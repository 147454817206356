import { create } from "zustand";

// Define the store state type
type AllocationDndStore = {
  items: Record<string, string[]>;
  itemsOriginal: Record<string, string[]>;
  hasUnallocatedCosts: boolean;
  unallocatedCosts: string;
  isGroup: boolean;
  isAllocationOwner: boolean;
  setIsAllocationOwner: (value: boolean) => void;
  setIsGroup: (value: boolean) => void;
  setHasUnallocatedCosts: (value: boolean) => void;
  setUnallocatedCosts: (value: string) => void;
  addItem: (item: string) => void;
  removeItem: (item: string) => void;
  setItems: (items: Record<string, string[]>) => void;
  setAllocationName: (value: string) => void;
  clear: () => void;
  allocationName: string;
};

// Create the store
const useAllocationDndStore = create<AllocationDndStore>((set) => ({
  // Initial state
  items: { main: [] },
  itemsOriginal: { main: [] },
  hasUnallocatedCosts: false,
  unallocatedCosts: "Unallocated",
  allocationName: "",
  isGroup: false,
  isAllocationOwner: true,

  setIsAllocationOwner: (value) => {
    set(() => ({ isAllocationOwner: value }));
  },

  setIsGroup: (value) => {
    set(() => ({ isGroup: value }));
  },

  setHasUnallocatedCosts: (value) => {
    set(() => ({ hasUnallocatedCosts: value }));
  },

  setAllocationName: (value) => {
    set(() => ({ allocationName: value }));
  },

  setUnallocatedCosts: (value) => {
    set(() => ({ unallocatedCosts: value }));
  },

  addItem: (item) => {
    set((state) => ({
      items: { main: [...state.items.main, item] },
      itemsOriginal: { main: [...state.items.main, item] },
    }));
  },

  removeItem: (item) => {
    set((state) => ({ items: { main: state.items.main.filter((id) => id !== item) } }));
  },

  setItems: (items) => {
    set({ items: { main: items.main } });
  },
  clear: () => {
    set({
      allocationName: "",
      unallocatedCosts: "Unallocated",
      hasUnallocatedCosts: false,
      isGroup: false,
      isAllocationOwner: true,
      items: { main: [] },
      itemsOriginal: { main: [] },
    });
  },
}));

export default useAllocationDndStore;
