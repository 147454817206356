import { useEffect, useRef, useState } from "react";

import { useHistory, useParams } from "react-router";
import { AccessLevel, type CloudConnectCategory } from "@doitintl/cmp-models";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Box,
  Button,
  capitalize,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Typography,
} from "@mui/material";
import { type AxiosError } from "axios";

import { useApiContext } from "../../../api/context";
import { CopyCodeBlock } from "../../../Components/CopyCodeBlock/CopyCodeBlock";
import LoadingButton from "../../../Components/LoadingButton";
import { useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../../utils";
import mixpanel from "../../../utils/mixpanel";
import { InnerScreenHeader } from "../Components/InnerScreenHeader";
import { BigQueryDatasetLocationSelect } from "./BigQueryDatasetLocationSelect";
import { commandGenerators } from "./commands";
import { FeaturesTable } from "./FeaturesTable";
import { useIsEntitledToCategory, usePermissionCategoriesByEntity, useSelectItemList } from "./hooks";

type CreateResponse = {
  isValid: boolean;
  clientId: string;
};

export const NewConnectionPage = () => {
  const { customer } = useCustomerContext();
  const { type: entity } = useParams<{ type: AccessLevel }>();
  const history = useHistory();
  const api = useApiContext();

  const showSuccess = useSuccessSnackbar();

  const availableCategories = usePermissionCategoriesByEntity(entity);
  const isEntitledToCategory = useIsEntitledToCategory();

  const requiredCategoryIds = availableCategories
    .filter((category) => category.isDefault)
    .map((category) => category.id);

  const [selectedFeatures, onFeatureSelect] = useSelectItemList<CloudConnectCategory["id"]>([]);

  const [selectedDatasetLocation, setSelectedDatasetLocation] = useState<string | null>(null);

  // After categories are loaded, select all of them by default
  useEffect(() => {
    availableCategories.forEach((category) => {
      if (!isEntitledToCategory(category)) {
        return;
      }
      onFeatureSelect(category.id, true);
    });
  }, [availableCategories, isEntitledToCategory, onFeatureSelect]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isUploadingFile, setIsUploadingFile] = useState(false);

  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorText, setErrorText] = useState<string | null>(null);

  const uploadFileRef = useRef<HTMLInputElement>(null);

  const backToSettings = () => {
    history.push(`/customers/${customer.id}/settings/gcp`);
  };

  // Based on the available categories and selected features, get a list of unique permissions needed
  const permissionsToAdd = availableCategories
    .filter((category) => selectedFeatures.includes(category.id))
    .flatMap((category) => category.permissions as unknown as string)
    .filter((permission, index, self) => self.indexOf(permission) === index);

  const commandGenerator = commandGenerators[entity];

  const commands = commandGenerator ? commandGenerator(permissionsToAdd) : [];

  // Depending on which permissions are selected, we need additional info
  const requireDatasetLocation = permissionsToAdd.includes("bigquery.datasets.create");
  // If we require dataset location, we need to make sure it's selected
  const canSubmitFile = !requireDatasetLocation || !!selectedDatasetLocation;

  const doFileUpload = async () => {
    if (!uploadFileRef.current) {
      return;
    }

    const file = uploadFileRef.current.files?.[0];

    if (!file) {
      return;
    }

    setIsUploadingFile(true);
    setErrorText(null);

    const formData = new FormData();
    formData.append("service_key", file);

    if (requireDatasetLocation && selectedDatasetLocation) {
      formData.append("location", selectedDatasetLocation);
    }

    try {
      // For non-organization scope we pass "/partial", else normal endpoint
      const url = `/v1/customers/${customer.id}/cloudconnect/google-cloud${
        entity !== AccessLevel.ORGANIZATION ? "/partial" : ""
      }`;

      const response = await api.request<CreateResponse>({
        method: "post",
        url,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      mixpanel.track("credentials.google-cloud.set");

      // Run some onboarding tasks - we need these only for organizations, and they are not critical
      if (entity === AccessLevel.ORGANIZATION) {
        const promises: Promise<void>[] = [];

        const clientId = response.data?.clientId;

        if (clientId) {
          promises.push(
            api.request({
              method: "post",
              url: `/v1/customers/${customer.id}/bq-lens/onboarding`,
              data: { clientId },
            })
          );
        }

        promises.push(
          api.request({
            method: "get",
            url: `/v1/customers/${customer.id}/google-cloud/service-limits`,
          }),
          api.request({
            method: "post",
            url: `/v1/customers/${customer.id}/recommender/update`,
          }),
          api.request({
            method: "get",
            url: `/v1/customers/${customer.id}/attachDashboard`,
          })
        );

        try {
          await Promise.allSettled(promises);
        } catch (error) {
          consoleErrorWithSentry(error);
        }
      }

      setIsUploadingFile(false);
      setIsDrawerOpen(false);
      backToSettings();
      showSuccess(`${capitalize(entity)} successfully connected`);
    } catch (error: unknown) {
      consoleErrorWithSentry(error);
      setIsUploadingFile(false);

      const axiosError = error as AxiosError<{ error?: string }>;
      const errorMessage = axiosError.response?.data?.error;

      if (errorMessage?.includes("Permission 'iam.serviceAccounts.getAccessToken' denied")) {
        setErrorText(
          "Service account impersonation failed. Please bind the Service Account Token Creator role to the DoiT service account and try again."
        );
      } else if (errorMessage?.includes("Enable it by")) {
        setErrorText("One or more required APIs are not enabled. Please enable them and try again.");
      }

      setIsErrorDialogOpen(true);
    }
  };

  const openFileSelect = () => {
    if (!uploadFileRef.current) {
      return;
    }

    // Clear the file input so onChange event is triggered even if the same file is selected
    uploadFileRef.current.value = "";
    uploadFileRef.current.click();
  };

  if (!commands.length) {
    return (
      <CardContent sx={{ textAlign: "center", py: 6 }}>
        <Container maxWidth="sm">
          <Typography variant="h5" gutterBottom>
            No features available
          </Typography>
          <Typography variant="body1" color="textSecondary" mb={4}>
            There are currently no features available to enable for your {entity}. Please check back later or contact
            support for more information.
          </Typography>
          <Button variant="contained" color="primary" onClick={backToSettings}>
            Back to settings
          </Button>
        </Container>
      </CardContent>
    );
  }

  return (
    <>
      <Card>
        <InnerScreenHeader title={`Connect ${entity}`} subtitle={customer.name} backPageCallback={backToSettings} />
        <CardContent>
          <Container maxWidth="md" sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="body1" mb={3}>
              Select features you'd like to enable for your {entity}
            </Typography>

            <FeaturesTable
              entity={entity}
              selectedFeatures={selectedFeatures}
              onFeatureSelect={onFeatureSelect}
              forceIncludeCategoryIds={requiredCategoryIds}
            />

            <Button
              variant="contained"
              sx={{ my: 5, alignSelf: "flex-end" }}
              disabled={selectedFeatures.length === 0}
              onClick={() => {
                setIsDrawerOpen(true);

                // TODO(laura): check if this is still valid - leftovers from V1
                mixpanel.track("gcp.link-account");
                if (selectedFeatures.includes("core")) {
                  mixpanel.track("gcp.link-account.quotas");
                }
              }}
            >
              Generate gcloud commands
            </Button>
          </Container>
        </CardContent>
      </Card>

      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
        }}
        PaperProps={{ sx: { maxWidth: "650px" } }}
      >
        <DialogTitle>Create Google Cloud service account</DialogTitle>
        <DialogContent>
          {requireDatasetLocation && (
            <BigQueryDatasetLocationSelect
              location={selectedDatasetLocation}
              setLocation={setSelectedDatasetLocation}
            />
          )}

          <Typography variant="body1">
            Run these gcloud commands in Google Cloud Shell to create a service account and role with selected
            permissions.
          </Typography>

          <Button
            variant="outlined"
            size="medium"
            startIcon={<OpenInNewIcon />}
            target="_blank"
            href="https://shell.cloud.google.com/?fromcloudshell=true&show=terminal"
            sx={{ my: 3 }}
          >
            Open Google Cloud Shell
          </Button>

          {commands.map((command) => (
            <Box key={command.header} mb={2} data-cy="command-block">
              <Typography variant="body1" sx={{ opacity: 0.6 }} mb={1}>
                {command.header}
              </Typography>
              {command.body ? <CopyCodeBlock base={command.body} /> : null}
            </Box>
          ))}

          <input
            type="file"
            ref={uploadFileRef}
            style={{ display: "none" }}
            onChange={async () => {
              await doFileUpload();
            }}
          />

          <LoadingButton
            variant="contained"
            loading={isUploadingFile}
            disabled={!canSubmitFile}
            mixpanelEventId="settings.gcp-platform-configuration.upload-key"
            onClick={() => {
              openFileSelect();
            }}
          >
            Upload file
          </LoadingButton>
        </DialogContent>
      </Drawer>

      <Dialog
        open={isErrorDialogOpen}
        onClose={() => {
          setIsErrorDialogOpen(false);
        }}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">An error occurred while connecting the {entity}</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText mb={2}>
            <Typography variant="body1">This error can happen for two reasons:</Typography>

            <Typography variant="body1">
              <ol>
                <li>
                  <strong>Changes may take some time to take effect.</strong> Please wait a few minutes and try again.
                </li>
                <li>
                  {/* Add specific error message here if we have one */}
                  <strong>Check your configuration.</strong> {errorText}
                </li>
              </ol>
            </Typography>

            <Typography variant="body1"> If the problem persists, please contact support.</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              // Close the dialog so the user can review the commands and then try again
              setIsErrorDialogOpen(false);
            }}
          >
            Review commands
          </Button>
          <Button
            variant="contained"
            onClick={async () => {
              // Just run the file upload again
              setIsErrorDialogOpen(false);
              await doFileUpload();
            }}
          >
            Try again
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
