import { useEffect } from "react";

import { ContractTypeDoitCloudIntelligence, ContractTypeLooker, DoitRole } from "@doitintl/cmp-models";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";

import { useDoitRoleCheck } from "../../../../../Components/hooks/useDoitRoles";
import EntitySelect from "../../../../../Components/Selects/EntitySelect";
import { isProduction } from "../../../../../constants";
import { isNext10Contract } from "../../../utils";
import { useContractFormContext } from "../../ContractsFormContext";
import { ifProductWithoutEntity, isDoiTCloudIntelligence } from "../../utils";
import { AssetsAutocomplete } from "../components/AssetsAutocomplete";
import PointOfSaleSelect from "./../components/PointOfSaleSelect";
import { AccountManagerSelector } from "./components/AccountManagerSelector";
import { CancelContractButton } from "./components/CancelContractButton";
import { ContractTypeSelector } from "./components/ContractTypeSelector";
import { DeleteContractButton } from "./components/DeleteContractButton";
import { LookerSalesProcessField } from "./components/LookerSalesProcessField";
import { TerminateContractButton } from "./components/TerminateContractButton";
import { UndoCancellationButton } from "./components/UndoCancellationButton";
import { UndoContractTerminationButton } from "./components/UndoContractTerminationButton";

export const GeneralStep = () => {
  const { state, handleChange } = useContractFormContext();
  const isDoitContractAdmin = useDoitRoleCheck(DoitRole.ContractAdmin);
  const isDoitContractOwner = useDoitRoleCheck(DoitRole.ContractOwner);

  const showCancelContractButton =
    state.editMode &&
    state.active &&
    ((isNext10Contract(state.type) && (isDoitContractAdmin || !isProduction)) ||
      (!isNext10Contract(state.type) && isDoitContractAdmin) ||
      isDoitContractOwner);

  const showDeleteContractButton = state.editMode && isDoitContractOwner;

  const showUndoContractCancellationButton =
    state.editMode &&
    !state.active &&
    !state.terminated &&
    !state.isEditForbidden &&
    !isNext10Contract(state.type) &&
    state.type !== ContractTypeDoitCloudIntelligence;

  const showTerminateContractButton =
    state.editMode &&
    state.active &&
    (isDoitContractAdmin || isDoitContractOwner) &&
    !isNext10Contract(state.type) &&
    state.type !== ContractTypeDoitCloudIntelligence;

  const showUndoContractTerminationButton =
    state.editMode &&
    !state.active &&
    state.terminated &&
    (isDoitContractAdmin || isDoitContractOwner) &&
    !isNext10Contract(state.type);

  useEffect(() => {
    if (isDoiTCloudIntelligence(state) && state.pointOfSale !== "doit") {
      handleChange("entity")({ target: { value: undefined } });
    }
  }, [handleChange, state, state.type]);

  return (
    <Container maxWidth="sm" data-cy="general-step">
      <Grid container spacing={1}>
        <Grid size={12}>
          <ContractTypeSelector />
        </Grid>
        {isDoiTCloudIntelligence(state) && state.pointOfSale && (
          <Grid size={12}>
            <PointOfSaleSelect
              value={state.pointOfSale}
              onChange={handleChange("pointOfSale")}
              margin="dense"
              fullWidth
            />
          </Grid>
        )}
        {(("pointOfSale" in state && state.pointOfSale && !ifProductWithoutEntity(state.type, state.pointOfSale)) ||
          isDoiTCloudIntelligence(state)) && (
          <Grid size={12}>
            <EntitySelect
              fullWidth
              margin="dense"
              value={state.entity}
              onChange={handleChange("entity")}
              helperText="Select billing profile"
              error={state.errors.entity}
              disabled={(isDoiTCloudIntelligence(state) && state.pointOfSale !== "doit") || state.isEditForbidden}
            />
          </Grid>
        )}
        {state.type === ContractTypeLooker && (
          <Grid size={12}>
            <AssetsAutocomplete
              disabled={state.isEditForbidden}
              label="Google Cloud billing account"
              helperText="Restrict contract to an asset"
              textFieldProps={{
                error: state.errors?.assets,
                required: true,
              }}
            />
          </Grid>
        )}

        <Grid size={12}>
          <AccountManagerSelector />
        </Grid>

        {state.type === ContractTypeLooker && (
          <Grid size={12}>
            <LookerSalesProcessField />
          </Grid>
        )}

        {showCancelContractButton && (
          <Grid>
            <CancelContractButton />
          </Grid>
        )}

        {showUndoContractCancellationButton && (
          <Grid>
            <UndoCancellationButton />
          </Grid>
        )}

        {showTerminateContractButton && (
          <Grid>
            <TerminateContractButton />
          </Grid>
        )}

        {showUndoContractTerminationButton && (
          <Grid>
            <UndoContractTerminationButton />
          </Grid>
        )}

        {showDeleteContractButton && (
          <Grid>
            <DeleteContractButton />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};
