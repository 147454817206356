import { useState } from "react";

import DashboardHead from "./DashboardHead";
import DashboardList from "./DashboardList";

const CloudDiagramsDashboard = () => {
  const [searchQuery, setSearchQuery] = useState("");

  return (
    <>
      <DashboardHead onSearch={setSearchQuery} />
      <DashboardList searchQuery={searchQuery} />
    </>
  );
};

export default CloudDiagramsDashboard;
