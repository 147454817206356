import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";

import { AvaOpeningSource, useAvaDialogContext } from "../../../Components/Ava2/AvaDialogContextProvider";
import { useDarkThemeCheck } from "../../../Components/hooks/useDarkThemeCheck";

export const AVAButton = () => {
  const isDarkTheme = useDarkThemeCheck();

  const { handleOpenAvaDialog } = useAvaDialogContext();
  return (
    <>
      <Tooltip title="Ava - AI assistant" placement="bottom">
        <IconButton
          disableFocusRipple
          sx={{
            padding: (theme) => theme.spacing(0.75, 0.75),
            color: "white",
            borderRadius: 1.5,
            "&:hover": {
              backgroundColor: isDarkTheme ? "#454551" : "#262A50",
            },
          }}
          onClick={() => {
            handleOpenAvaDialog(AvaOpeningSource.NAV);
          }}
        >
          <AutoAwesomeIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};
