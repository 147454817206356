import { AccessLevel, type CloudConnectCategory, type CustomerModelCloudConnectModel } from "@doitintl/cmp-models";

import { type Customer } from "../../../types";

// For each AccessLevel, define which permissions to remove. These are the permissions that only apply to different scopes
const scopeConfigs: Record<AccessLevel, (category: CloudConnectCategory) => string[]> = {
  [AccessLevel.DATASET]: (category) => [
    ...(category.orgLevelOnlyPermissions ?? []),
    ...(category.projectLevelOnlyPermissions ?? []),
  ],
  [AccessLevel.PROJECT]: (category) => [
    ...(category.orgLevelOnlyPermissions ?? []),
    ...(category.datasetLevelOnlyPermissions ?? []),
  ],
  [AccessLevel.ORGANIZATION]: (category) => [
    ...(category.projectLevelOnlyPermissions ?? []),
    ...(category.datasetLevelOnlyPermissions ?? []),
  ],
};

// Returns the category's base permissions minus any that are specific to a different scope.
export function getScopedCategoryPermissions(category: CloudConnectCategory, scope: AccessLevel): string[] {
  // If this scope does not exist in scopeConfigs, return everything.
  const removeList = scopeConfigs[scope]?.(category) ?? [];

  // Filter out any permissions that are in removeList.
  return category.permissions.filter((permission) => !removeList.includes(permission));
}

export const getProjectIdFromServiceAccount = (clientEmail?: string): string | undefined =>
  clientEmail?.split("@")[1]?.split(".")[0];

interface GcpCloudConnectLinkParams {
  customer: Customer;
  cloudConnect?: CustomerModelCloudConnectModel;
  callbackUrl?: string;
}

export const getGcpCloudConnectSettingsLink = ({
  customer,
  cloudConnect,
  callbackUrl,
}: GcpCloudConnectLinkParams): string => {
  let base = `/customers/${customer.id}/settings/gcp`;

  if (cloudConnect) {
    base += `/connect/${cloudConnect.scope}/${cloudConnect.id}`;
  }

  if (callbackUrl) {
    base += `?callbackUrl=${encodeURIComponent(callbackUrl)}`;
  }

  return base;
};
