import { EarlyAccessFeature } from "@doitintl/cmp-models";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Button, Stack, Typography } from "@mui/material";

import { CopyCodeBlock } from "../../../../Components/CopyCodeBlock/CopyCodeBlock";
import { useFeatureFlag } from "../../../../Components/hooks/useFeatureFlag";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import mixpanel from "../../../../utils/mixpanel";
import { getCLICreateCommand } from "../../../Settings/AWS/ManagePermissions/FeaturesSelection/cloudFormationHelper";

interface AwsCliConnectionProps {
  featureKey: string;
  mixpanelFeatureId: string;
}

const AwsCliConnection = ({ featureKey, mixpanelFeatureId }: AwsCliConnectionProps) => {
  const { customer } = useCustomerContext();
  const isFSK8SFeatureEnabled = useFeatureFlag(EarlyAccessFeature.FSK8S);

  const command = getCLICreateCommand(customer.id, [featureKey], isFSK8SFeatureEnabled, "");

  return (
    <Stack
      spacing={2}
      sx={{
        pt: 3,
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: 500,
        }}
      >
        Run the following command in AWS CloudShell
      </Typography>
      <Box data-testid="aws-cli-command">
        <Button
          variant="outlined"
          color="primary"
          startIcon={<OpenInNewIcon />}
          href="https://console.aws.amazon.com/cloudshell/home"
          target="_blank"
          onClick={() => {
            mixpanel.track(`${mixpanelFeatureId}.CLI-commands.set-up-${mixpanelFeatureId}-on-aws-console`);
          }}
        >
          Open AWS CloudShell
        </Button>
      </Box>
      <CopyCodeBlock base={command[0]} />
    </Stack>
  );
};

export default AwsCliConnection;
