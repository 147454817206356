import { useState } from "react";

import { useHistory } from "react-router";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Collapse, Container, Divider, Stack, Typography } from "@mui/material";

import { useApiContext } from "../../../api/context";
import { useInfoSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCustomerContext } from "../../../Context/CustomerContext";
import AwsAccountSelector from "../../K8s/ClusterActivation/Steps/components/AwsAccountSelector";
import { useClustersWithCloudConnect } from "../../K8s/hooks";
import { useAWSAccountInfo, useAWSAccounts } from "../../Settings/AWS/hooks";
import { AWSFeatureName } from "../../Settings/AWS/types";
import { importAWSAccountCD } from "../import";
import { AwsFeatureConnection } from "./AwsFeatureConnection";
import DiagramCreationModeSelector, { DiagramCreationMode } from "./DiagramCreationModeSelector";

enum Mode {
  InProgress,
  Success,
  Error,
}

const pageWidth = 798;
const footerXPadding = 2;
const muiScalingFactor = 8;

const eksDiscoveryWaitTimeSeconds = 5;
const maxRetries = 3;

const CloudDiagramsOnboarding = () => {
  const { customer } = useCustomerContext();
  const api = useApiContext();
  const history = useHistory();
  const infoSnackbar = useInfoSnackbar();

  const [mode, setMode] = useState<Mode>(Mode.InProgress);
  const [selectedAccountId, setSelectedAccountId] = useState<string | null>(null);
  const [clusters] = useClustersWithCloudConnect();
  const [accounts] = useAWSAccounts();
  const [selectedCreationMode, setSelectedCreationMode] = useState<DiagramCreationMode>(
    DiagramCreationMode.CREATE_WITH_CLUSTERS
  );
  const [cloudDiagramsImportLoading, setCloudDiagramsImportLoading] = useState(false);
  const [discoveryInProgress, setDiscoveryInProgress] = useState(false);

  const account = accounts.find((a) => a.accountId === selectedAccountId);
  const accountClusters = clusters?.filter((c) => c.projectId === account?.accountId);

  const [enabledFeatures] = useAWSAccountInfo(selectedAccountId || "");

  const cloudDiagramsFeatureKey = AWSFeatureName.cloud_diagrams;

  const startK8sOnboarding = () => {
    history.push(
      `/customers/${customer.id}/assets/kubernetes/activate/eks/cloud-diagrams?projectId=${account?.accountId}`
    );
  };

  const onFinishDiscovery = (retryCount = 0) => {
    if (accountClusters.length === 0) {
      if (retryCount < maxRetries) {
        setTimeout(
          () => {
            onFinishDiscovery(retryCount + 1);
          },
          eksDiscoveryWaitTimeSeconds * 1000 * 2 ** retryCount // exponential backoff
        );
      } else {
        setDiscoveryInProgress(false);
        infoSnackbar("No clusters found in the AWS account");
      }
    } else {
      setDiscoveryInProgress(false);
      startK8sOnboarding();
    }
  };

  const startDiscovery = () => {
    setDiscoveryInProgress(true);
    setTimeout(() => {
      onFinishDiscovery();
    }, eksDiscoveryWaitTimeSeconds * 1000);
  };

  const handleCancel = () => {
    history.push(`/customers/${customer.id}/cloud-diagrams`);
  };

  const createDiagram = async () => {
    await importAWSAccountCD({
      api,
      customer,
      handleCancel,
      setIsImporting: setCloudDiagramsImportLoading,
      account,
    });
  };

  const handleContinue = () => {
    if (selectedCreationMode === DiagramCreationMode.CREATE_WITHOUT_CLUSTERS) {
      createDiagram();
    } else {
      startDiscovery();
    }
  };

  const handleFeatureUpdated = () => {
    setMode(Mode.Success);
  };

  const featureIsHealthy =
    (enabledFeatures.includes(AWSFeatureName.cloud_diagrams) && enabledFeatures.length > 0) || mode === Mode.Success;

  const accountWasAlreadyConnected = featureIsHealthy && mode !== Mode.Success;

  return (
    <Container>
      <Box
        sx={{
          mt: 6,
          mb: 4,
          mx: "auto",
          maxWidth: pageWidth,
        }}
      >
        <Stack spacing={4}>
          <Typography variant="h1">Select an AWS account to import</Typography>

          <Stack spacing={2}>
            <AwsAccountSelector
              selectedAccountId={selectedAccountId}
              onSelect={setSelectedAccountId}
              targetFeatures={[cloudDiagramsFeatureKey]}
            />

            {selectedAccountId && (
              <AwsFeatureConnection
                featureKey={cloudDiagramsFeatureKey}
                accountId={selectedAccountId}
                onUpdateFeature={handleFeatureUpdated}
                header="How do you want to connect Cloud Diagrams?"
                instructions={
                  <ol style={{ paddingLeft: 16 }}>
                    <li>
                      <Typography>
                        Select 'Set up Cloud diagrams' to add Cloud diagrams to the selected AWS account
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        You'll be taken to your AWS console to create a CloudFormation stack from a template
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        After creating the CloudFormation stack, you may have to wait about 30 seconds while we update
                        your account
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        After we obtain the necessary permissions, you can either discover and enable EKS clusters on
                        the diagram or create a diagram without EKS clusters
                      </Typography>
                    </li>
                  </ol>
                }
              />
            )}
          </Stack>

          <Collapse
            in={!!selectedAccountId && featureIsHealthy}
            style={{ marginTop: featureIsHealthy ? undefined : 0 }}
          >
            <DiagramCreationModeSelector
              onChange={setSelectedCreationMode}
              value={selectedCreationMode}
              accountWasAlreadyConnected={accountWasAlreadyConnected}
              accountClusters={accountClusters}
            />
          </Collapse>
        </Stack>
      </Box>

      <Box
        sx={{
          bgcolor: "background.paper",
          position: "fixed",
          bottom: 0,
          width: "100%",
          left: 0,
          zIndex: 20,
        }}
      >
        <Divider sx={{ gridColumn: "1/13" }} />
        <Box
          sx={{
            mx: "auto",
            maxWidth: pageWidth + footerXPadding * 2 * muiScalingFactor,
            display: "flex",
            py: 1,
            px: footerXPadding,
            margin: "auto",
            justifyContent: "flex-end",
          }}
        >
          <Stack spacing={2} direction="row">
            <Button onClick={handleCancel} size="large">
              Cancel
            </Button>
            <LoadingButton
              onClick={handleContinue}
              loading={discoveryInProgress || cloudDiagramsImportLoading}
              variant="contained"
              disabled={!selectedAccountId || !featureIsHealthy}
              size="large"
            >
              Continue
            </LoadingButton>
          </Stack>
        </Box>
      </Box>
    </Container>
  );
};

export default CloudDiagramsOnboarding;
