import { type AttributionFilter } from "@doitintl/cmp-models";
import isEqual from "lodash/isEqual";
import * as yup from "yup";
import { create, type StoreApi, type UseBoundStore } from "zustand";

import { type AttributionWRef } from "../../../../types";
import { getLetterForIndex } from "../../../../utils/string";

const areParenthesesBalanced = (str: string): boolean => {
  let count = 0;
  for (const char of str) {
    if (char === "(") count++;
    if (char === ")") count--;
    if (count < 0) return false;
  }
  return count === 0;
};

const containsAllElements = (str: string, elements: string[]): boolean =>
  elements.every((element) => {
    // Match the element as a whole word
    const regex = new RegExp(`\\b${element}\\b`, "g");
    return regex.test(str);
  });

const operators = ["AND", "OR", "NOT"];

const formSchema = yup.object({
  name: yup.string().trim().min(1).required("Name is required"),
  filterFields: yup.array().min(1, "At least one filter field is required"),
  formula: yup.string().test({
    name: "is-valid-formula",
    test(value, context) {
      const elements = context.parent.filterFields.map((_, idx) => getLetterForIndex(idx));
      if (!value) return this.createError({ message: "Formula cannot be empty" });

      // Check if parentheses are balanced
      if (!areParenthesesBalanced(value)) {
        return this.createError({ message: "Parentheses are not balanced" });
      }

      // Check if all elements are included
      if (!containsAllElements(value, elements)) {
        const missingElements = elements.filter((element) => {
          const regex = new RegExp(`\\b${element}\\b`, "g");
          return !regex.test(value);
        });

        return this.createError({
          message: `Missing elements: ${missingElements.join(", ")}`,
        });
      }

      // Check for valid operator usage
      const tokens = value.split(/\s+/).filter(Boolean);

      // Empty formula check
      if (tokens.length === 0) {
        return this.createError({ message: "Formula cannot be empty" });
      }

      // Check for operators at the beginning (except NOT)
      if (["AND", "OR"].includes(tokens[0])) {
        return this.createError({
          message: "Formula cannot start with AND, OR",
        });
      }

      // Check for operators at the end
      if (operators.includes(tokens[tokens.length - 1])) {
        return this.createError({
          message: "Formula cannot end with an operator",
        });
      }

      // Check for consecutive operators (except NOT before another operator)
      for (let i = 0; i < tokens.length - 1; i++) {
        if (operators.includes(tokens[i]) && operators.includes(tokens[i + 1]) && tokens[i] !== "NOT") {
          return this.createError({
            message: `Invalid operator sequence: ${tokens[i]} ${tokens[i + 1]}`,
          });
        }
      }

      // Check for consecutive elements (need operator between them)
      for (let i = 0; i < tokens.length - 1; i++) {
        const isElement1 = elements.includes(tokens[i]) || tokens[i] === ")";
        const isElement2 = elements.includes(tokens[i + 1]) || tokens[i + 1] === "(";

        if (isElement1 && isElement2) {
          return this.createError({
            message: `Missing operator between "${tokens[i]}" and "${tokens[i + 1]}"`,
          });
        }
      }

      // Check for invalid tokens (not elements, not full operators, not parentheses)
      const validTokens = [...elements, ...operators, "(", ")"];
      const invalidTokens = tokens.filter((token) => !validTokens.includes(token));

      if (invalidTokens.length > 0) {
        // Check if any invalid tokens look like they might be unknown elements
        // (not operators, not parentheses, and not valid elements)
        const possibleUnknownElements = invalidTokens.filter(
          (token) => !token.includes("(") && !token.includes(")") && !operators.some((op) => token.includes(op))
        );

        if (possibleUnknownElements.length > 0) {
          return this.createError({
            message: `Unknown element(s): ${possibleUnknownElements.join(", ")}. Only use elements from the provided list: ${elements.join(", ")}.`,
          });
        }

        return this.createError({
          message: `Invalid token(s): ${invalidTokens.join(", ")}. Only use full operator names (AND, OR, NOT).`,
        });
      }

      return true;
    },
  }),
});

type FormValues = {
  id?: string | null;
  name: string;
  filterFields: AttributionFilter[];
  formula: string;
};

export type ViewType = "new" | "existing";

export type FormState = {
  values: FormValues;
  originalValues: FormValues | null;
  errors: Record<string, string | Record<string, string>[]>;
  isSubmitting: boolean;
  isEditMode: boolean;
  isDirty: boolean;
  isValid: boolean;
  viewType: ViewType;
  selectedAllocation: AttributionWRef | null;
  editWarnings: string;
};

export type Rule = {
  values: FormValues;
  selectedAllocation: AttributionWRef | null;
};

export type FormActions = {
  setName: (name: string) => void;
  setFilterFields: (fields: AttributionFilter[]) => void;
  setFormula: (formula: string) => void;
  resetForm: () => void;
  setEditMode: (data: FormValues) => void;
  exitEditMode: () => void;
  setSelectedAllocation: (allocation: AttributionWRef | null) => void;
  setViewType: (viewType: ViewType) => void;
  validateForm: () => void;
  setEditWarnings: (value: string) => void;
};

const initialValues: FormValues = {
  id: null,
  name: "",
  filterFields: [],
  formula: "",
};

const checkFormDirty = (newValues: FormValues, originalValues: FormValues | null): boolean =>
  !isEqual(newValues, originalValues ?? initialValues);

const createValueSetter =
  <K extends keyof FormValues>(key: K) =>
  (set: StoreApi<FormState>["setState"], get: StoreApi<FormState>["getState"]) =>
  async (value: FormValues[K]) => {
    set((state: FormState) => {
      const newValues = {
        ...state.values,
        [key]: value,
      };

      const { selectedAllocation, viewType } = state;
      const isEditSelected = viewType === "existing" && !!selectedAllocation;
      const originalValues = isEditSelected
        ? {
            id: selectedAllocation?.ref.id,
            name: selectedAllocation?.data.name,
            filterFields: selectedAllocation?.data.filters || [],
            formula: selectedAllocation?.data.formula || "",
          }
        : state.originalValues;

      const isDirty = checkFormDirty(newValues, originalValues);

      return {
        values: newValues,
        isDirty,
      };
    });

    // Run validation after state update
    try {
      const state = get();
      await formSchema.validate(state.values, { abortEarly: false });
      set({ isValid: true, errors: {} });
      // If validation passes, update external save permission store
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const errors = error.inner.reduce(
          (acc, err) => ({
            ...acc,
            ...(err.path ? { [err.path]: err.message } : {}),
          }),
          {}
        );
        set({ isValid: false, errors });
      }
    }
  };

const createFormStore = () =>
  create<FormState & FormActions>((set, get) => {
    const initialState: FormState = {
      values: initialValues,
      originalValues: null,
      errors: {},
      isSubmitting: false,
      isEditMode: false,
      isDirty: false,
      isValid: false,
      viewType: "new",
      selectedAllocation: null,
      editWarnings: "",
    };

    const actions: FormActions = {
      setName: createValueSetter("name")(set, get),
      setFilterFields: createValueSetter("filterFields")(set, get),
      setFormula: createValueSetter("formula")(set, get),

      setSelectedAllocation: (allocation: AttributionWRef | null) => {
        set((state) => ({ selectedAllocation: allocation, values: { ...state.values, id: allocation?.ref.id } }));
      },

      setEditWarnings: (value: string) => {
        set(() => ({ editWarnings: value }));
      },

      setViewType: (viewType: ViewType) => {
        set({ viewType });
        if (viewType === "new") {
          set({ values: { id: null, name: "", filterFields: [], formula: "" }, selectedAllocation: null });
        } else {
          set({ values: { ...get().values, id: get().selectedAllocation?.ref.id } });
        }
      },

      setEditMode: (data: FormValues) => {
        set({
          ...initialState,
          values: data,
          originalValues: data,
          isEditMode: true,
        });
      },

      exitEditMode: () => {
        set(initialState);
      },

      resetForm: () => {
        const state = get();
        set({
          ...initialState,
          ...(state.isEditMode && state.originalValues ? { values: state.originalValues } : {}),
        });
      },

      validateForm: async () => {
        const state = get();
        try {
          await formSchema.validate(state.values, { abortEarly: false });
          set({ isValid: true, errors: {} });
        } catch (error) {
          if (error instanceof yup.ValidationError) {
            const errors = error.inner.reduce(
              (acc, err) => ({
                ...acc,
                ...(err.path ? { [err.path]: err.message } : {}),
              }),
              {}
            );
            set({ isValid: false, errors });
          }
        }
      },
    };

    return {
      ...initialState,
      ...actions,
    };
  });

class FormStoreManager {
  private stores: Map<string, UseBoundStore<StoreApi<FormState & FormActions>>> = new Map();

  getStore(id: string) {
    if (!this.stores.has(id)) {
      this.stores.set(id, createFormStore());
    }
    return this.stores.get(id)!;
  }

  removeStore(id: string) {
    this.stores.delete(id);
  }

  clear() {
    this.stores.clear();
  }
}

export const formStoreManager = new FormStoreManager();
