import { useCallback, useMemo, useState } from "react";

import { AccessLevel, type CloudConnectCategory, TierPackageTypes } from "@doitintl/cmp-models";

import { useTier } from "../../../Context/TierProvider";
import { capitalizeStartCase } from "../../../utils/common";
import { isHeritageTier } from "../General/utils";
import { usePermissionCategories } from "./usePermissionCategories";
import { getScopedCategoryPermissions } from "./utils";

export function useSelectItemList<T>(initialState: T[]): [T[], (value: T, isSelected: boolean) => void] {
  const [selectedItems, setSelectedItems] = useState(initialState);

  const onSelectItem = useCallback(
    (value: T, isSelected: boolean) => {
      setSelectedItems((current) => {
        if (isSelected) {
          return [...current, value];
        }

        return current.filter((currentId) => currentId !== value);
      });
    },
    [setSelectedItems]
  );

  return [selectedItems, onSelectItem];
}

export const usePermissionCategoriesByEntity = (entity: AccessLevel) => {
  const categories = usePermissionCategories();
  const { getCustomerTier } = useTier();

  const isHeritageCustomer = isHeritageTier(getCustomerTier(TierPackageTypes.NAVIGATOR));

  return useMemo(() => {
    let relevantCategories: CloudConnectCategory[];

    if (entity === AccessLevel.ORGANIZATION) {
      relevantCategories = categories
        .map((category) => {
          const scopedPermissions = getScopedCategoryPermissions(category, AccessLevel.ORGANIZATION);
          return { ...category, permissions: scopedPermissions };
        })
        .filter((category) => category.permissions.length > 0);
    } else {
      const accessProperty = `allow${capitalizeStartCase(entity)}AccessLevel`;
      relevantCategories = categories
        .filter((category) => category[accessProperty])
        .map((category) => {
          const scopedPermissions = getScopedCategoryPermissions(category, entity);
          return { ...category, permissions: scopedPermissions };
        })
        .filter((category) => category.permissions.length > 0);
    }

    if (!isHeritageCustomer) {
      relevantCategories = relevantCategories.filter((category) => !category.heritageOnly);
    }

    return relevantCategories;
  }, [categories, entity, isHeritageCustomer]);
};
export const useIsEntitledToCategory = () => {
  const { isFeatureEntitled } = useTier();

  return useCallback(
    (category: CloudConnectCategory) => {
      // If there's no restrictions set, the user is entitled to the category
      if (!category.requiredEntitlements?.length) {
        return true;
      }

      // If there's any restriction set, the user must have all of them to be entitled to the category
      return category.requiredEntitlements.every((entitlement) => isFeatureEntitled(entitlement));
    },
    [isFeatureEntitled]
  );
};
