import { type MouseEvent, useCallback, useEffect, useMemo, useState } from "react";

import { useHistory } from "react-router";
import { AssetTypeGoogleCloud, type CustomerModel, type CustomersColumnId, DoitRole } from "@doitintl/cmp-models";
import { type QueryModel } from "@doitintl/models-firestore";
import EditIcon from "@mui/icons-material/Edit";
import { Alert, Box, Button, Card, CardHeader, CircularProgress, Divider, Link, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { makeStyles } from "@mui/styles";
import flatMap from "lodash/flatMap";
import uniqBy from "lodash/uniqBy";

import { myCustomersListTexts, myTeamsCustomersListTexts } from "../../assets/texts";
import CustomerCreateDialog from "../../Components/CreateCustomer/CustomerCreateDialog";
import { DoitConsoleTitle } from "../../Components/DoitConsoleTitle";
import { FilterTable } from "../../Components/FilterTable/FilterTable";
import Hide from "../../Components/HideChildren/Hide";
import { useDoitRoleCheck } from "../../Components/hooks/useDoitRoles";
import useQueryString from "../../Components/hooks/useQueryString";
import { useAuthContext } from "../../Context/AuthContext";
import { AccountManagersHooks } from "../../Context/customer/AccountManagers";
import { partnerAssetsMap } from "../../utils/common";
import { useFullScreen } from "../../utils/dialog";
import { AggregationCards } from "./AggregationCards";
import { filterColumns as filters, headers } from "./columns";
import { GCPPartnerAccessHeaders } from "./consts";
import { CustomerRow } from "./CustomerRow";
import { CustomizeTableViewDialog } from "./CustomizeTableViewDialog";
import { EmptyState } from "./EmptyState";
import { useCustomersEnrichment } from "./hooks";
import {
  type BaseCustomerRowListItem,
  type CountUpCardProps,
  DoerType,
  type PageViewMode,
  PageViewModes,
} from "./types";
import { UpdateAccountManagerDialog } from "./UpdateAccountManagerDialog";

const useStyles = makeStyles(() => ({
  root: {},
  card: {
    padding: 8,
    paddingBottom: 0,
  },
  customizeTableViewButton: {
    marginTop: 3,
    marginLeft: 3,
    marginRight: 3,
  },
}));

const Customers = () => {
  const { mode } = useQueryString();
  const { accountManager } = AccountManagersHooks.useAccountManager();
  const viewMode: PageViewMode = (mode?.toString() as PageViewMode) || PageViewModes.myCustomers;
  const isMyCustomersPage = viewMode === PageViewModes.myCustomers;
  const classes = useStyles();
  const { isDoitPartner, partnerCompany } = useAuthContext({ mustHaveUser: true });
  const [customerCreateOpen, setCustomerCreateOpen] = useState(false);
  const [aggregationCards, setAggregationCards] = useState<CountUpCardProps[]>([]);
  const [customizeAnchorEl, setCustomizeAnchorEl] = useState<HTMLButtonElement | null>(null);
  const history = useHistory();
  const {
    loadCustomerDataEnrichment,
    userColumns,
    loadUserColumns,
    updateUserColumns,
    customers,
    getMyCustomerQueries,
    getMyTeamCustomerQueries,
    getBaseColumns,
    getColumnsConfig,
  } = useCustomersEnrichment(isMyCustomersPage);
  const [error, setError] = useState<Error | undefined>(undefined);
  const canCreateCustomerTenant = useDoitRoleCheck(DoitRole.CreateCustomerTenant);

  const partnerHeaders = useMemo((): CustomersColumnId[] => {
    if (isDoitPartner) {
      if (partnerAssetsMap[partnerCompany] === AssetTypeGoogleCloud) {
        return GCPPartnerAccessHeaders;
      }
      return getBaseColumns();
    }
    return [];
  }, [isDoitPartner, partnerCompany, getBaseColumns]);

  const commonProcessCustomerQueries = useCallback(
    (queries: QueryModel<CustomerModel>[] | undefined) => {
      if (!queries) {
        return;
      }
      const limit = 1000;
      setError(undefined);
      Promise.all([loadUserColumns(), ...queries.map((query) => query.limit(limit).get())]).then(
        async ([userColumns, ...customersRes]) => {
          const customersModels = flatMap(customersRes, (querySnapshot) =>
            querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ref: doc.modelRef,
              ...doc.asModelData(),
            }))
          );

          const uniqByCustomersModels = uniqBy(customersModels, "id");

          const userConfiguredColumns = isDoitPartner ? partnerHeaders : userColumns;
          try {
            await loadCustomerDataEnrichment(uniqByCustomersModels, userConfiguredColumns);
          } catch (error: any) {
            setError(error);
          }
        }
      );
    },
    [isDoitPartner, loadCustomerDataEnrichment, loadUserColumns, partnerHeaders]
  );

  useEffect(() => {
    if (isMyCustomersPage) {
      return;
    }

    getMyTeamCustomerQueries().then((queries) => {
      commonProcessCustomerQueries(queries);
    });
  }, [isMyCustomersPage, getMyTeamCustomerQueries, commonProcessCustomerQueries]);

  useEffect(() => {
    if (!isMyCustomersPage) {
      return;
    }

    getMyCustomerQueries().then((queries) => {
      commonProcessCustomerQueries(queries);
    });
  }, [isMyCustomersPage, getMyCustomerQueries, commonProcessCustomerQueries]);

  const handleClickCustomerCreate = () => {
    setCustomerCreateOpen(true);
  };

  const handleClickGoToAccountManagersPage = () => {
    history.push(`/account-managers`);
  };

  const switchViewMode = () => {
    switch (viewMode) {
      case PageViewModes.myCustomers:
        history.push(`/customers?mode=my-teams-customers`);
        break;
      case PageViewModes.myTeamsCustomers:
        history.push(`/customers`);
        break;
    }
  };

  const handleCloseCustomerCreate = useCallback(
    (id: string) => {
      setCustomerCreateOpen(false);
      if (id) {
        history.push(`/customers/${id}`);
      }
    },
    [history]
  );

  const onFilterApplied = (rows: readonly BaseCustomerRowListItem[]) => {
    const totalThisMonth = rows.reduce((total, row) => total + (row.thisMonth ?? 0), 0);
    const totalLastMonth = rows.reduce((total, row) => total + (row.lastMonth ?? 0), 0);
    const customersTrend = totalLastMonth > 0 ? ((totalThisMonth - totalLastMonth) / totalLastMonth) * 100 : 0;

    setAggregationCards([
      { text: "Customers", number: rows.length },
      { text: "Month To Date Spend", number: totalThisMonth, decimals: 2, prefix: "$" },
      { text: "Trend versus last period", number: customersTrend, decimals: 1, suffix: "%" },
    ]);
  };

  const headerColumns = useMemo(() => {
    if (isDoitPartner) {
      return headers.filter((header) => partnerHeaders.find((h) => h === header.value));
    } else {
      return headers.filter((header) => userColumns.find((u) => u.id === header.value));
    }
  }, [isDoitPartner, partnerHeaders, userColumns]);

  const filterColumns = useMemo(() => {
    if (isDoitPartner) {
      return filters.filter((filter) => partnerHeaders.find((h) => h === filter.path));
    }
    return filters.filter((filter) => userColumns.find((u) => u.id === filter.path));
  }, [isDoitPartner, partnerHeaders, userColumns]);

  const userType = useMemo(() => {
    const isFsr = !!accountManager;
    const doer = isFsr ? DoerType.Fsr : DoerType.Regular;
    return isDoitPartner ? DoerType.Partner : doer;
  }, [accountManager, isDoitPartner]);

  const handleUpdateUserColumns = useCallback(
    async (newColumns: CustomersColumnId[]) => {
      setCustomizeAnchorEl(null);
      if (!customers) {
        return;
      }
      await Promise.all([updateUserColumns(newColumns), loadCustomerDataEnrichment(customers, newColumns)]);
    },
    [customers, loadCustomerDataEnrichment, updateUserColumns]
  );

  const handleEditColumnsClick = (event: MouseEvent<HTMLButtonElement>) => {
    setCustomizeAnchorEl(event.currentTarget);
  };

  const { isMobile: isXs } = useFullScreen("sm");

  const headerTitle = () => {
    let [title, link] = ["", ""];
    switch (viewMode) {
      case PageViewModes.myCustomers:
        title = myCustomersListTexts.TITLE;
        link = myCustomersListTexts.SWITCH_VIEW;
        break;
      case PageViewModes.myTeamsCustomers:
        title = myTeamsCustomersListTexts.TITLE;
        link = myTeamsCustomersListTexts.SWITCH_VIEW;
        break;
    }

    return (
      <Typography
        variant="h1"
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {title}
        <Divider orientation="vertical" flexItem variant="middle" sx={{ ml: 2, mr: 2 }} />
        <Link variant="body1" underline="none" onClick={switchViewMode} sx={{ cursor: "pointer", mt: 0.5 }}>
          {link}
        </Link>
      </Typography>
    );
  };

  if (error) {
    // This throw will be caught by the ErrorBoundary
    throw error;
  }

  if (!customers) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "40vh",
          width: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className={classes.root}>
      <DoitConsoleTitle pageName="Customers" />
      {isDoitPartner && customers.length === 0 && <Alert severity="info">{myCustomersListTexts.NO_CUSTOMERS}</Alert>}
      <Card className={classes.card}>
        <CardHeader
          disableTypography={true}
          title={headerTitle()}
          subheader={isMyCustomersPage ? myCustomersListTexts.SUBHEADER : myTeamsCustomersListTexts.SUBHEADER}
          action={
            isMyCustomersPage &&
            !isDoitPartner &&
            customers.length > 0 && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleClickCustomerCreate}
                disabled={userType !== DoerType.Fsr && !canCreateCustomerTenant}
              >
                {isXs ? "New" : "New customer"}
              </Button>
            )
          }
        />
      </Card>
      <>
        {customers.length === 0 ? (
          <EmptyState
            isMyCustomersPage={isMyCustomersPage}
            onClick={isMyCustomersPage ? handleClickCustomerCreate : handleClickGoToAccountManagersPage}
            doerType={userType}
          />
        ) : (
          <AggregationCards cards={aggregationCards} />
        )}

        {customers.length > 0 && (
          <FilterTable
            filterBarPlaceholder="Filter by name, domain, trend, account manager"
            defaultSortingColumnValue="primaryDomain"
            defaultSortDirection="asc"
            tableItems={customers}
            rowComponent={({ row }) => <CustomerRow row={row} />}
            wrapRowComponent={false}
            headerColumns={headerColumns}
            filterColumns={filterColumns}
            persistenceKey="customers_table"
            onFilterApplied={onFilterApplied}
          >
            {!isDoitPartner && (
              <Hide smDown>
                <Box
                  sx={{
                    display: "flex",
                    pt: 1.3,
                  }}
                >
                  <Divider sx={{ ml: 3, mr: 1 }} orientation="vertical" flexItem />
                  <Grid>
                    <Button
                      className={classes.customizeTableViewButton}
                      onClick={handleEditColumnsClick}
                      variant="text"
                      color="primary"
                    >
                      <EditIcon sx={{ mr: 1 }} />
                      Edit columns
                    </Button>
                  </Grid>
                </Box>
              </Hide>
            )}
          </FilterTable>
        )}
      </>
      {customerCreateOpen && <CustomerCreateDialog onClose={handleCloseCustomerCreate} />}
      <CustomizeTableViewDialog
        openEl={customizeAnchorEl}
        onClose={() => {
          setCustomizeAnchorEl(null);
        }}
        handleCustomizeConfirm={handleUpdateUserColumns}
        userColumnsConfig={userColumns}
        columnsConfig={getColumnsConfig()}
      />
      {isDoitPartner && accountManager && !accountManager.name && <UpdateAccountManagerDialog />}
    </div>
  );
};

export default Customers;
