import { useCallback, useMemo } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import OpenInNewOutlined from "@mui/icons-material/OpenInNewOutlined";
import { Grid2 as Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";

import { attributionGroupsText, globalText } from "../../../assets/texts";
import { allocationTxt } from "../../../assets/texts/CloudAnalytics/allocation";
import useAllocationDndStore from "./hooks/useAllocationDnd";

export const UnallocatedCosts = () => {
  const { items, setHasUnallocatedCosts, setUnallocatedCosts, unallocatedCosts } = useAllocationDndStore();
  const canRemove = useMemo(() => items.main.length === 1, [items.main]);

  const handleRemove = useCallback(() => {
    setHasUnallocatedCosts(false);
  }, [setHasUnallocatedCosts]);

  const handleOnChange = useCallback(
    (e) => {
      setUnallocatedCosts(e.target.value);
    },
    [setUnallocatedCosts]
  );

  return (
    <Paper
      variant="outlined"
      sx={({ palette }) => ({
        py: 2,
        paddingLeft: 3,
        paddingRight: 2,
        backgroundColor: palette.general.backgroundDark,
      })}
    >
      <Grid container>
        <Grid size={11}>
          <Typography variant="subtitle1" fontWeight={500} mb={2}>
            {allocationTxt.UNALLOCATED_COSTS}
          </Typography>
        </Grid>

        {canRemove && (
          <Grid size={1} container justifyContent="flex-end" alignContent="center">
            <Tooltip title={globalText.REMOVE}>
              <IconButton onClick={handleRemove} size="medium">
                <DeleteIcon fontSize="small" color="action" sx={{ pointerEvents: "none" }} />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>

      <Typography variant="body2" color="text.secondary" mb={2}>
        {allocationTxt.UNALLOCATED_COSTS_DESC}

        <Link href="https://help.doit.com/docs/cloud-analytics/attribution-groups" underline="none" target="_blank">
          <Typography
            component="span"
            variant="body2"
            color="primary"
            sx={{ display: "inline-flex", alignItems: "center", gap: 0.5 }}
          >
            {allocationTxt.COST_ALLOCATION}
            <OpenInNewOutlined fontSize="inherit" />
          </Typography>
        </Link>
      </Typography>

      <TextField
        name="title"
        variant="outlined"
        value={unallocatedCosts}
        onChange={handleOnChange}
        placeholder={attributionGroupsText.UNALLOCATED}
        sx={({ palette }) => ({ maxWidth: 540, width: "100%", backgroundColor: palette.general.backgroundDefault })}
      />
      <Typography color="text.secondary" fontSize={12}>
        {allocationTxt.UNALLOCATED_COSTS_HELPER_TEXT}
      </Typography>
    </Paper>
  );
};
