import { DoitRole, EarlyAccessFeature } from "@doitintl/cmp-models";

import FlexsaveCalculator from "../../Components/FlexsaveCalculator/FlexsaveCalculator";
import AccountManagers from "../../Pages/AccountManagers/AccountManagers";
import AnomalyDetection from "../../Pages/AnomalyDetection";
import CostAnomalyDetails from "../../Pages/AnomalyDetection/CostAnomalyDetails";
import Assets from "../../Pages/Assets/Assets";
import CreateGSuiteAsset from "../../Pages/Assets/Forms/CreateGSuiteAsset";
import { CreateOffice365AssetWrapper } from "../../Pages/Assets/Forms/CreateOffice365AssetWrapper";
import { CreateBetterCloudAsset, CreateZendeskAsset } from "../../Pages/Assets/Forms/CreateOfflineAsset";
import { AwsAccountGenerator } from "../../Pages/AwsAccountGenerator/AwsAccountGenerator";
import Billing from "../../Pages/Billing/Billing";
import BillingProfiles from "../../Pages/BillingProfiles/BillingProfiles";
import { AnalyticsAlertStepper } from "../../Pages/CloudAnalytics/alerts";
import AlertCreate from "../../Pages/CloudAnalytics/alerts/AlertCreate";
import AnalyticsAlertsBrowser from "../../Pages/CloudAnalytics/alerts/AnalyticsAlertsBrowser";
import { BudgetBrowser } from "../../Pages/CloudAnalytics/budgets/BudgetBrowser";
import { BudgetWrapper } from "../../Pages/CloudAnalytics/budgets/BudgetWrapper";
import { CreateBudgetWrapper } from "../../Pages/CloudAnalytics/budgets/CreateBudgetWrapper";
import { ViewBudget } from "../../Pages/CloudAnalytics/budgets/ViewBudget";
import { CloudAnalytics } from "../../Pages/CloudAnalytics/CloudAnalytics";
import CloudDiagramsDashboard from "../../Pages/CloudDiagrams/CloudDiagramsDashboard";
import { CloudDiagramsOnboarding } from "../../Pages/CloudDiagrams/CloudDiagramsOnboarding";
import Cloudflow from "../../Pages/Cloudflow/Cloudflow";
import Collection from "../../Pages/Collection/Collection";
import { CommitmentManager } from "../../Pages/CommitmentManager/CommitmentManager";
import { CommitmentManagerList } from "../../Pages/CommitmentManager/CommitmentManagerList";
import { CreateCommitmentFlow } from "../../Pages/CommitmentManager/CreateCommitmentFlow";
import { ContractsTabs } from "../../Pages/ContractsTabs";
import CreditsAdjustments from "../../Pages/CreditsAdjustments";
import { CustomerView } from "../../Pages/Customer/CustomerView";
import CustomerViewForPartner from "../../Pages/Customer/CustomerViewForPartner";
import AzureOnboarding from "../../Pages/Customer/SaaSConsoleOnboarding/Azure/AzureOnboarding";
import DatadogOnboarding from "../../Pages/Customer/SaaSConsoleOnboarding/Datadog/DatadogOnboarding";
import SaaSOnboardingAWS from "../../Pages/Customer/SaaSConsoleOnboarding/SaaSOnboardingAWS";
import SaaSOnboardingGCP from "../../Pages/Customer/SaaSConsoleOnboarding/SaaSOnboardingGCP";
import SnowflakeOnboarding from "../../Pages/Customer/SaaSConsoleOnboarding/Snowflake/SnowflakeOnboarding";
import Customers from "../../Pages/Customers/Customers";
import DataHubRouter from "../../Pages/DataHub/DataHubRouter";
import { Dashboard as EksDashboard } from "../../Pages/EKS/Dashboard";
import { Dashboard as EksDashboardV2 } from "../../Pages/EKSSplit/Dashboard";
import { EditEntityPage } from "../../Pages/Entity/EditEntityPage";
import Entity from "../../Pages/Entity/Entity";
import { EntityForm } from "../../Pages/Entity/EntityForm";
import { FlexsaveAWSWrapper } from "../../Pages/Flexsave/FlexsaveAWSWrapper";
import { FlexsaveStandaloneAWS } from "../../Pages/Flexsave/FlexsaveStandaloneAWS";
import { FlexsaveGCPPricelist } from "../../Pages/Flexsave/GCPPricelist/FlexsaveGCPPricelist";
import FlexsaveAWSOps from "../../Pages/Flexsave/Operations/AWS/FlexsaveAWSOps";
import { Standalone } from "../../Pages/Flexsave/Standalone/AWS/Standalone";
import { CustomerRoot } from "../../Pages/Home/CustomerRoot";
import IAM from "../../Pages/IAM/Iam";
import { InsightsRouter } from "../../Pages/Insights/InsightsRouter";
import Integrations from "../../Pages/Integrations/Integrations";
import { Invoices } from "../../Pages/Invoices/Invoices";
import {
  BulkClusterActivation,
  ClusterAgentInstallation,
  EksCloudDiagramsClusterActivation,
  EksClusterActivation,
  GkeClusterActivation,
} from "../../Pages/K8s";
import { AllKnownIssues } from "../../Pages/KnownIssues/AllKnownIssues";
import { KnownIssues } from "../../Pages/KnownIssues/KnownIssues";
import { KnownIssueView } from "../../Pages/KnownIssueView";
import Marketplace from "../../Pages/Marketplace";
import { MarketplaceError } from "../../Pages/Marketplace/Messages/MarketplaceError";
import { MarketplaceSuccessAWS } from "../../Pages/Marketplace/Messages/MarketplaceSuccessAWS";
import { MarketplaceSuccessGCP } from "../../Pages/Marketplace/Messages/MarketplaceSuccessGCP";
import MasterPayerAccount from "../../Pages/MPA/MasterPayerAccount";
import OptimizerPlan from "../../Pages/OptimizerPlan";
import { planIdParam } from "../../Pages/OptimizerPlan/constants";
import OrderHistory from "../../Pages/OrderHistory/OrderHistory";
import PerksRouter from "../../Pages/Perks/PerksRouter";
import UploadPlesForm from "../../Pages/PLES/UploadPlesForm";
import Pricebooks from "../../Pages/Pricebooks/Pricebooks";
import ProductWorkshop from "../../Pages/ProductWorkshop/ProductWorkshop";
import Sandbox from "../../Pages/Sandbox";
import { AuthenticationSettings, Settings } from "../../Pages/Settings";
import { isHeritageTier } from "../../Pages/Settings/General/utils";
import NotificationCenter from "../../Pages/Settings/Notifications/index";
import { SolveOnboarding } from "../../Pages/SolveOnboarding/SolveOnboarding";
import SpotScaling from "../../Pages/SpotScaling";
import StartTrialStepper from "../../Pages/Subscription/StartTrialStepper";
import { ThreadsRouter } from "../../Pages/Threads/ThreadsRouter";
import TierManagement from "../../Pages/Tiering/TierManagement";
import { UserViewFromProfile } from "../../Pages/UserView/UserView";
import { Whois } from "../../Pages/Whois/Whois";
import Support from "../../Support";
import CustomerTicket from "../../Support/Components/CustomerTicket";
import {
  type AccessControlConfig,
  type CustomerAccessControlDefinition,
  type CustomerRuntimeState,
  type DoitAccessControlDefinition,
  type StaticAccessControlDefinition,
} from "../types";
import { isAllowedToOnboardStandaloneAsset } from "./utils";

const hasStandaloneRoutesAccess = (state: CustomerRuntimeState) =>
  state.flexsaveAWSConfigured ||
  state.flexsaveGCPConfigured ||
  state.saasConsoleAWSConfigured ||
  state.saasConsoleGCPConfigured ||
  state.saasConsoleAzureConfigured;

const doitEmployeeOnlyRoutes: DoitAccessControlDefinition[] = [
  {
    component: Marketplace,
    key: "Marketplace",
    routes: [
      {
        pageId: "marketplace:gcp:accounts",
        route: "/marketplace/gcp-accounts",
      },
      {
        pageId: "marketplace:gcp:entitlements",
        route: "/marketplace/gcp-entitlements",
      },
      {
        pageId: "marketplace:gcp:adjustments",
        route: "/marketplace/gcp-adjustments",
        allowAccess: (permissions) => permissions.check(DoitRole.MarketplaceAdmin),
      },
      {
        pageId: "marketplace:aws:accounts",
        route: "/marketplace/aws-accounts",
      },
      {
        pageId: "marketplace:aws:entitlements",
        route: "/marketplace/aws-entitlements",
      },
    ],
  },
  {
    component: FlexsaveAWSOps,
    key: "FlexsaveAWSOps",
    pageId: "flexsave-aws-operations",
    route: "/flexsave-aws-operations",
  },
  {
    component: OptimizerPlan,
    key: "optimizer-plan",
    pageId: "optimizer-plan",
    route: `/optimizer-plan/:${planIdParam}`,
  },
  {
    component: AwsAccountGenerator,
    key: "AwsAccountGenerator",
    pageId: "aws-account-generator",
    route: "/aws-account-generator",
    allowAccessToDoitPartners: false,
    allowAccess: (permissions) => permissions.check(DoitRole.AwsAccountGenerator),
  },
  {
    component: Customers,
    key: "Customers",
    pageId: "customers",
    route: "/customers",
    allowAccessToDoitPartners: true,
  },
  {
    component: CreateBetterCloudAsset,
    key: "BetterCloudAssetCreate",
    pageId: "bettercloud-asset-create",
    route: "/customers/:customerId/assets/bettercloud/create",
  },
  {
    component: CreateZendeskAsset,
    key: "ZenDeskAssetCreate",
    pageId: "zendesk-asset-create",
    route: "/customers/:customerId/assets/zendesk/create",
  },
  {
    component: Whois,
    key: "Whois",
    pageId: "whois",
    route: "/whois",
  },
  {
    component: CreditsAdjustments,
    key: "CreditsAdjustmentsGlobal",
    routes: [
      {
        pageId: "credits:global",
        route: "/credits",
      },
      {
        pageId: "adjustments:global",
        route: "/adjustments",
      },
    ],
  },
  {
    component: CustomerTicket,
    key: "CustomerTicket",
    route: "/ticket/:ticketId",
    pageId: "support:customer-ticket",
  },
  {
    component: AccountManagers,
    key: "AccountManagers",
    pageId: "account-managers",
    route: "/account-managers",
  },
  {
    component: Pricebooks,
    key: "Pricebooks",
    routes: [
      {
        pageId: "price-books",
        route: "/pricebooks",
      },
      {
        pageId: "price-books:gcp",
        route: "/pricebooks/google-cloud",
      },
      {
        pageId: "price-books:aws",
        route: "/pricebooks/amazon-web-services",
      },
      {
        pageId: "price-books:aws:details",
        route: "/pricebooks/amazon-web-services/:id",
      },
    ],
  },
  {
    component: Billing,
    key: "Billing",
    requiresDoitOwner: true,
    routes: [
      { pageId: "billing", route: "/billing" },
      {
        pageId: "billing:invoicing",
        route: "/billing/invoicing",
      },
      {
        pageId: "billing:on-hold",
        route: "/billing/on-hold",
        allowAccess: (permissions) =>
          permissions.check(DoitRole.InvoicingAdmin) || permissions.check(DoitRole.InvoicingToolsUser),
      },
      {
        pageId: "billing:draft-invoices",
        route: "/billing/draft-invoices",
        allowAccess: (permissions) =>
          permissions.check(DoitRole.InvoicingAdmin) || permissions.check(DoitRole.InvoicingToolsUser),
      },
      {
        pageId: "billing:draft-invoices:view",
        route: "/billing/draft-invoices/:entityId/:billingMonth/:draftInvoiceId/view",
        allowAccess: (permissions) =>
          permissions.check(DoitRole.InvoicingAdmin) || permissions.check(DoitRole.InvoicingToolsUser),
      },
      {
        pageId: "billing:billing-tools",
        route: "/billing/billing-tools",
        allowAccess: (permissions) =>
          permissions.check(DoitRole.InvoicingAdmin) ||
          permissions.check(DoitRole.InvoicingToolsUser) ||
          permissions.check(DoitRole.InvoicingRecalculationUser) ||
          permissions.check(DoitRole.InvoicingCopyBillingTablesUser),
      },
      { pageId: "billing:cogs", route: "/billing/cogs" },
    ],
  },
  {
    component: AllKnownIssues,
    key: "AllKnownIssues",
    pageId: "known-issues",
    route: "/known-issues",
  },
  {
    component: KnownIssueView,
    key: "KnownIssueViewDoer",
    pageId: "known-issues:details",
    route: "/known-issues/:knownIssueId",
  },
  {
    component: Collection,
    key: "Collection",
    routes: [
      {
        pageId: "collection",
        route: "/collection",
      },
      {
        pageId: "collection:debt",
        route: "/debt",
      },
      {
        pageId: "collection:cash",
        route: "/cash",
      },
    ],
  },
  {
    component: FlexsaveCalculator,
    key: "FlexsaveCalculator",
    pageId: "flexsave-calculator",
    route: "/flexsave-calculator",
  },
  {
    component: MasterPayerAccount,
    key: "MPA",
    pageId: "master-payer-account",
    route: "/mpa-ops",
  },
  {
    component: UploadPlesForm,
    key: "PLES",
    pageId: "partner-led-enterprise-support",
    route: "/partner-led-enterprise-support",
  },
  {
    component: TierManagement,
    key: "TierManagement",
    routes: [
      {
        pageId: "tier-management",
        route: "/tiers",
      },
      {
        pageId: "entitlements-management",
        route: "/entitlements",
      },
      {
        pageId: "tier-management:edit-tier",
        route: "/tiers/:tierId",
      },
    ],
  },
];

const commonMarketplaceRoutes: CustomerAccessControlDefinition[] = [
  {
    component: MarketplaceSuccessGCP,
    key: "MarketplaceSuccessGCP",
    pageId: "flexsave-gcp-marketplace-success",
    route: "/gcp-marketplace-success/:product",
  },
  {
    component: MarketplaceError,
    key: "MarketplaceError",
    pageId: "flexsave-gcp-marketplace-error",
    route: "/gcp-marketplace-error/:product",
  },
  {
    component: MarketplaceSuccessAWS,
    key: "MarketplaceSuccessAWS",
    pageId: "aws-marketplace-success",
    route: "/aws-marketplace-success/:product",
  },
];

// routes that are shared for resold and standalone customers
const commonCustomerRoutes: CustomerAccessControlDefinition[] = [
  ...commonMarketplaceRoutes,
  {
    component: CustomerRoot,
    key: "CustomerRoot",
    pageId: "customer-root",
    route: "/",
  },
  {
    component: CustomerView,
    key: "CustomerView",
    routes: [
      {
        pageId: "customer-dashboards",
        route: "/dashboards/:name?",
        presentationModeSupported: true,
      },
      {
        pageId: "home-page",
        route: "/home",
        presentationModeSupported: true,
      },
    ],
  },
  {
    component: UserViewFromProfile,
    key: "UserView",
    pageId: "user-profile",
    route: "/profile/:userId/:tab?",
    allowAccess: (permissions, customerState) => {
      if (permissions.doitEmployee) {
        return true;
      }

      const match = customerState.matchPath<{ userId: string }>("/profile/:userId/:tab?");
      return match?.params.userId === permissions.userId;
    },
  },
  {
    component: EditEntityPage,
    key: "EditEntityPage",
    pageId: "entity:edit",
    route: "/entities/:entityId/edit",
    allowAccess: (permissions) => permissions.billingProfilesAdmin || permissions.doitEmployee,
  },
  {
    component: Entity,
    key: "EntityView",
    pageId: "entity-view",
    route: "/entities/:entityId/:tab?",
    allowAccess: (permissions) => permissions.billingProfilesAdmin || permissions.doitEmployee,
  },
  {
    component: EntityForm,
    key: "EntityCreate",
    pageId: "entity-create",
    route: "/entities",
    allowAccess: (permissions) => permissions.billingProfilesAdmin || permissions.doitEmployee,
  },
  {
    component: () => <Standalone filterCompletedAccounts={true} />,
    key: "StandaloneOnboardingAWS",
    pageId: "flexsave-aws-onboarding",
    route: "/flexsave-aws-onboarding",
    allowAccess: (permissions) => permissions.flexsaveAdmin || permissions.doitEmployee,
  },
  {
    component: EksDashboard,
    key: "EKSOnboarding",
    routes: [
      {
        pageId: "eks-onboarding",
        route: "/eks-onboarding",
        featureGate: "lens:eks",
      },
      {
        pageId: "eks-onboarding:connect",
        route: "/eks-onboarding/:clusterId",
        featureGate: "lens:eks",
      },
    ],
    allowAccess: (permissions) => permissions.doitEmployee || permissions.manageSettings,
  },
  {
    component: EksDashboardV2,
    key: "EKSOnboarding2",
    routes: [
      {
        pageId: "eks-onboarding-v2",
        route: "/eks-onboarding-v2",
        featureGate: "lens:eks",
      },
      {
        pageId: "eks-onboarding-v2:connect",
        route: "/eks-onboarding-v2/:clusterId",
        featureGate: "lens:eks",
      },
    ],
    allowAccess: (permissions) => permissions.doitEmployee || permissions.manageSettings,
  },
  {
    component: Cloudflow,
    key: "Cloudflow",
    allowAccess: (permissions) =>
      permissions.doitEmployee || permissions.cloudFlowManager || permissions.cloudFlowViewer,
    routes: [
      {
        pageId: "cloudflow",
        route: "/cloudflow",
        presentationModeSupported: true,
      },
      {
        pageId: "cloudflow:edit",
        route: "/cloudflow/edit/:flowId",
      },
      {
        pageId: "cloudflow:history",
        route: "/cloudflow/history",
      },
      {
        pageId: "cloudflow:details",
        route: "/cloudflow/history/:executionId",
      },
      {
        pageId: "cloudflow:blueprints",
        route: "/cloudflow/blueprints",
      },
      {
        pageId: "blueprint:details",
        route: "/blueprint/:flowId",
      },
    ],
  },
  {
    component: InsightsRouter,
    key: "Insights",
    routes: [
      {
        pageId: "insights",
        route: "/insights",
        presentationModeSupported: true,
      },
      {
        pageId: "insights:newInsight",
        route: "/insights/new-insight/:insightKey?",
        presentationModeSupported: true,
        featureGate: "governance:insights",
      },
      {
        pageId: "insights:details",
        route: "/insights/:providerId/:insightKey",
        presentationModeSupported: true,
        featureGate: "governance:insights",
      },
    ],
    allowAccess: (permissions) => permissions.insightsManager || permissions.doitEmployee,
  },
  {
    component: ThreadsRouter,
    key: "Threads",
    routes: [
      {
        pageId: "threads",
        route: "/threads",
        presentationModeSupported: true,
        featureGate: "governance:threads",
      },
      {
        pageId: "threads:details",
        route: "/threads/:threadId?",
        presentationModeSupported: true,
        featureGate: "governance:threads",
      },
    ],
    allowAccess: (permissions) => permissions.threadsManager || permissions.doitEmployee,
  },
  {
    component: DataHubRouter,
    key: "DataHubRouter",
    presentationModeSupported: true,
    allowAccess: (permissions) => permissions.dataHubAdmin,
    routes: [
      {
        pageId: "datasets",
        route: "/datasets",
        presentationModeSupported: true,
      },
      {
        pageId: "datasets:details",
        route: "/datasets/:datasetName?",
        featureGate: "pdi:datahub",
        presentationModeSupported: true,
      },
    ],
  },
  {
    component: CreditsAdjustments,
    key: "CreditsAdjustmentsCustomer",
    routes: [
      {
        pageId: "credits:customer",
        route: "/credits",
        allowAccess: (permissions) => permissions.doitEmployee,
      },
      {
        pageId: "adjustments:customer",
        route: "/adjustments",
        allowAccess: (permissions) => permissions.doitEmployee,
      },
    ],
  },
  {
    component: NotificationCenter,
    key: "Notifications",
    routes: [
      {
        pageId: "notifications",
        route: "/notifications",
      },
      {
        pageId: "notifications:create",
        route: "/:audience/notifications/create",
      },
      {
        pageId: "notifications:edit",
        route: "/:audience/notifications/edit/:notificationId/:stepId?",
      },
    ],
  },
  {
    component: CreateGSuiteAsset,
    key: "CreateGSuiteAsset",
    pageId: "assets:g-suite:create",
    route: "/assets/g-suite/create",
    allowAccess: (permissions) => permissions.doitEmployee || permissions.assetsManager,
  },
  {
    component: CreateOffice365AssetWrapper,
    key: "CreateOffice365AssetWrapper",
    pageId: "assets:office-365:create",
    route: "/assets/office-365/create",
    allowAccess: (permissions) => permissions.doitEmployee || permissions.assetsManager,
  },
  {
    component: ClusterAgentInstallation,
    key: "ClusterAgentInstallation",
    pageId: "assets:kubernetes:agent:install",
    route: "/assets/kubernetes/:clusterId/agent/install",
    allowAccess: (permissions, state) =>
      permissions.doitEmployee ||
      (permissions.manageSettings && state.earlyAccessFeatures.includes(EarlyAccessFeature.FSK8S)),
  },
  {
    component: EksClusterActivation,
    key: "EksClusterActivation",
    pageId: "assets:kubernetes:activate:eks",
    route: "/assets/kubernetes/activate/eks",
    allowAccess: (permissions, state) =>
      permissions.doitEmployee ||
      (permissions.manageSettings && state.earlyAccessFeatures.includes(EarlyAccessFeature.CLOUDDIAGRAMS)),
  },
  {
    component: GkeClusterActivation,
    key: "GkeClusterActivation",
    pageId: "assets:kubernetes:activate:gke",
    route: "/assets/kubernetes/activate/gke",
    allowAccess: (permissions, state) =>
      permissions.doitEmployee ||
      (permissions.manageSettings && state.earlyAccessFeatures.includes(EarlyAccessFeature.FSK8S)),
  },
  {
    component: BulkClusterActivation,
    key: "BulkClusterActivation",
    pageId: "assets:kubernetes:activate:bulk",
    route: "/assets/kubernetes/activate/bulk",
    allowAccess: (permissions, state) =>
      permissions.doitEmployee ||
      (permissions.manageSettings && state.earlyAccessFeatures.includes(EarlyAccessFeature.FSK8S)),
  },
  {
    component: EksCloudDiagramsClusterActivation,
    key: "EksCloudDiagramsClusterActivation",
    pageId: "assets:kubernetes:activate:eks:cloud-diagrams",
    route: "/assets/kubernetes/activate/eks/cloud-diagrams",
    allowAccess: (permissions, state) =>
      permissions.doitEmployee ||
      (permissions.manageSettings && state.earlyAccessFeatures.includes(EarlyAccessFeature.CLOUDDIAGRAMS)),
  },
];

// static routes that are open to everyone
const commonStaticRoutes: StaticAccessControlDefinition[] = [
  {
    component: FlexsaveGCPPricelist,
    key: "FlexsaveGCPPricelist",
    pageId: "flexsave-gcp-pricelist",
    route: "/flexsave-gcp-pricelist",
  },
];

const budgetsRoutes: CustomerAccessControlDefinition[] = [
  {
    component: BudgetBrowser,
    key: "BudgetBrowser",
    pageId: "analytics:budgets",
    route: "/analytics/budgets",
    allowAccess: (permissions) =>
      permissions.budgetsManager || permissions.doitEmployee || permissions.cloudAnalyticsAdmin,
    featureGate: "governance:budgets",
    isFeatureGateDisabledForDoer: true,
    presentationModeSupported: true,
  },
  {
    component: CreateBudgetWrapper,
    key: "BudgetCreate",
    pageId: "analytics:budgets:create",
    route: "/analytics/budgets/create",
    allowAccess: (permissions) =>
      permissions.budgetsManager || permissions.doitEmployee || permissions.cloudAnalyticsAdmin,
    featureGate: "governance:budgets",
    isFeatureGateDisabledForDoer: true,
    presentationModeSupported: true,
  },
  {
    component: BudgetWrapper,
    key: "BudgetDetails",
    pageId: "analytics:budgets:details",
    route: "/analytics/budgets/:budgetId",
    allowAccess: (permissions) =>
      permissions.budgetsManager || permissions.doitEmployee || permissions.cloudAnalyticsAdmin,
    featureGate: "governance:budgets",
    isFeatureGateDisabledForDoer: true,
    presentationModeSupported: true,
  },
  {
    component: ViewBudget,
    key: "ViewBudget",
    pageId: "analytics:budgets:view",
    route: "/analytics/budgets/:budgetId/view",
    allowAccess: (permissions) =>
      permissions.budgetsManager || permissions.doitEmployee || permissions.cloudAnalyticsAdmin,
    featureGate: "governance:budgets",
    isFeatureGateDisabledForDoer: true,
    presentationModeSupported: true,
  },
];

const alertRoutes: CustomerAccessControlDefinition[] = [
  {
    component: AnalyticsAlertsBrowser,
    key: "AnalyticsAlertsBrowser",
    pageId: "analytics:alerts",
    route: "/analytics/alerts",
    featureGate: "governance:alerts",
    isFeatureGateDisabledForDoer: true,
    presentationModeSupported: true,
    allowAccess: (permissions) => permissions.cloudAnalyticsUser || permissions.doitEmployee,
  },
  {
    component: AnalyticsAlertStepper,
    key: "AnalyticsAlertStepper",
    pageId: "analytics:alerts:details",
    route: "/analytics/alerts/:alertId",
    featureGate: "governance:alerts",
    isFeatureGateDisabledForDoer: true,
    presentationModeSupported: true,
    allowAccess: (permissions) => permissions.cloudAnalyticsUser || permissions.doitEmployee,
  },
  {
    component: AlertCreate,
    key: "AlertCreate",
    pageId: "analytics:alerts:create",
    route: "/analytics/alerts/create",
    featureGate: "governance:alerts",
    isFeatureGateDisabledForDoer: true,
    presentationModeSupported: true,
    allowAccess: (permissions) => permissions.cloudAnalyticsUser || permissions.doitEmployee,
  },
];

// resold customer routes
export const accessControlConfig: AccessControlConfig = {
  // defines customer facing pages and routes (all the routes will be relative to /customers/:customerId )
  customer: [
    ...commonCustomerRoutes,

    {
      component: Assets,
      key: "Assets",
      routes: [
        {
          pageId: "assets",
          route: "/assets",
          presentationModeSupported: true,
        },
        {
          pageId: "asset:page",
          route: "/assets/:tab/:assetId",
          presentationModeSupported: true,
        },
        {
          pageId: "assets:all",
          route: "/assets/:tab?/:assetId?/:transfer?",
          presentationModeSupported: true,
        },
      ],
      allowAccess: (permissions) => permissions.doitEmployee || permissions.assetsManager,
    },
    {
      component: BillingProfiles,
      key: "BillingProfiles",
      routes: [
        {
          pageId: "billing-profiles",
          route: "/billing-profiles",
        },
      ],
      allowAccess: (permissions) => permissions.billingProfilesAdmin || permissions.doitEmployee,
    },
    {
      component: ContractsTabs,
      key: "ContractsTabs",
      routes: [
        {
          pageId: "contracts",
          route: "/contracts",
          allowAccess: (permissions) =>
            permissions.doitEmployee || permissions.invoicesViewer || permissions.contractsViewer,
        },
        {
          pageId: "ramp-plans",
          route: "/contracts/ramps",
          allowAccess: (permissions) => permissions.doitEmployee || permissions.rampPlansViewer,
        },
        {
          pageId: "ramp-plans:edit",
          route: "/contracts/ramps/:rampId",
          allowAccess: (permissions) => permissions.doitEmployee || permissions.rampPlansViewer,
        },
        {
          pageId: "contracts:list",
          route: "/contracts/contracts-list",
          allowAccess: (permissions) =>
            permissions.doitEmployee || permissions.invoicesViewer || permissions.contractsViewer,
        },
        {
          pageId: "contracts:create",
          route: "/contracts/contracts-list/create",
          allowAccess: (permissions) => permissions.doitEmployee,
        },
        {
          pageId: "contracts:edit",
          route: "/contracts/contracts-list/:contractId",
          allowAccess: (permissions) => permissions.doitEmployee,
        },
        {
          pageId: "contracts:view",
          route: "/contracts/contracts-list/:contractId/view",
          allowAccess: (permissions) => permissions.doitEmployee || permissions.contractsViewer,
        },
        {
          pageId: "contracts:view-vendor-contract",
          route: "/contracts/contracts-list/:contractId/view-vendor-contract",
          allowAccess: (permissions) => permissions.doitEmployee,
        },
      ],
      allowAccess: (permissions) =>
        permissions.doitEmployee || permissions.contractsViewer || permissions.rampPlansViewer,
    },
    {
      component: FlexsaveAWSWrapper,
      key: "FlexsaveAWSWrapper",
      pageId: "flexsave-aws",
      route: "/flexsave-aws",
      allowAccess: (permissions, state) =>
        (permissions.flexsaveAdmin || permissions.doitEmployee) &&
        (state.hasAWSAssets || state.earlyAccessFeatures.includes(EarlyAccessFeature.FLEXSAVE_AWS_STANDALONE)),
      presentationModeSupported: true,
    },
    {
      component: IAM,
      key: "IAM",
      routes: [
        {
          pageId: "iam:users",
          route: "/iam/users",
        },
        {
          pageId: "iam:users:details",
          route: "/iam/users/:userId/:tab?",
        },
        {
          pageId: "iam:roles",
          route: "/iam/roles",
        },
        {
          pageId: "iam:roles:details",
          route: "/iam/roles/:roleId",
        },
        {
          pageId: "iam:organizations",
          route: "/iam/orgs",
        },
        {
          pageId: "iam:organizations:create",
          route: "/iam/orgs/create",
          featureGate: "platform:IAMOrg",
        },
        {
          pageId: "iam:organizations:details",
          route: "/iam/orgs/:orgId",
        },
        {
          pageId: "iam:account-assumption",
          route: "/iam/account-assumption",
        },
        {
          pageId: "iam:users:profile:api",
          route: "/profile/api",
        },
      ],
      allowAccess: (permissions) => permissions.doitEmployee || permissions.usersManager,
    },
    {
      component: Sandbox,
      key: "Sandbox",
      pageId: "sandbox",
      route: "/sandbox",
      allowAccess: (permissions, state) =>
        !!isHeritageTier(state.navigatorTier) &&
        state.hasGCPAssets &&
        (permissions.doitEmployee || permissions.sandboxAdmin || permissions.sandboxUser),
      featureGate: "governance:sandboxes",
    },
    {
      component: OrderHistory,
      key: "OrderHistory",
      pageId: "orders",
      route: "/orders",
      allowAccess: (permissions) => permissions.doitEmployee || permissions.assetsManager || permissions.invoicesViewer,
    },
    {
      component: CommitmentManagerList,
      key: "CommitmentManagerList",
      pageId: "commitment-manager",
      route: "/commitment-manager",
      allowAccess: (permissions) => permissions.check(DoitRole.NordiaPreview),
    },
    {
      component: CreateCommitmentFlow,
      key: "create-commitment-flow",
      pageId: "commitment-manager:create",
      route: "/commitment-manager/create",
      allowAccess: (permissions) => permissions.check(DoitRole.NordiaPreview),
    },
    {
      component: CreateCommitmentFlow,
      key: "edit-commitment-flow",
      pageId: "commitment-manager:edit",
      route: "/commitment-manager/:commitmentId/edit",
      allowAccess: (permissions) => permissions.check(DoitRole.NordiaPreview),
    },
    {
      component: CommitmentManager,
      key: "commitment-manager-view",
      pageId: "commitment-manager:view",
      route: "/commitment-manager/:commitmentManagerId",
      allowAccess: (permissions) => permissions.check(DoitRole.NordiaPreview),
    },
    {
      component: Invoices,
      key: "InvoicesList",
      routes: [
        {
          pageId: "invoices",
          route: "/invoices",
        },
        {
          pageId: "invoices:details",
          route: "/invoices/:eid/:ivnum",
        },
      ],
      allowAccess: (permissions) => permissions.doitEmployee || permissions.invoicesViewer,
    },
    {
      component: SpotScaling,
      key: "SpotScaling",
      routes: [
        {
          pageId: "spot-scaling",
          route: "/spot-scaling",
          featureGate: "savings:spotScaling",
          presentationModeSupported: true,
        },
        {
          pageId: "spot-scaling:apply",
          route: "/spot-scaling/:asgName",
          featureGate: "savings:spotScaling",
          presentationModeSupported: true,
        },
      ],
      allowAccess: (permissions, state) =>
        (permissions.spotScaling || permissions.doitEmployee) && (state.hasAWSAssets || state.hasAWSStandaloneAssets),
    },

    {
      component: SolveOnboarding,
      key: "SolveOnboarding",
      routes: [
        {
          pageId: "solve-onboarding",
          route: "/solve-onboarding",
        },
      ],
      allowAccess: (permissions) => permissions.doitEmployee,
    },
    {
      component: Integrations,
      key: "integrations",
      routes: [
        {
          pageId: "integrations:zapier",
          route: "/integrations/zapier",
          featureGate: "platform:zapier",
        },
        {
          pageId: "integrations:slack",
          route: "/integrations/slack",
          allowAccess: (permissions) => permissions.doitEmployee || permissions.manageSettings,
        },
        {
          pageId: "integrations:jira",
          route: "/integrations/jira",
          featureGate: "governance:threads",
          presentationModeSupported: true,
          allowAccess: (permissions) => permissions.doitEmployee || permissions.manageSettings,
        },
        {
          pageId: "integrations:ms-teams",
          route: "/integrations/msteams",
        },
      ],
    },
    {
      component: Settings,
      key: "Settings",
      routes: [
        {
          pageId: "settings",
          route: "/settings",
        },
        {
          pageId: "settings:general",
          route: "/settings/general",
        },
        {
          pageId: "settings:gcp",
          route: "/settings/gcp",
        },
        {
          pageId: "settings:gcp:new",
          route: "/settings/gcp/connect/:type(organization|project|dataset)",
        },
        {
          pageId: "settings:gcp:edit",
          route: "/settings/gcp/connect/:type(organization|project|dataset)/:id",
        },
        {
          pageId: "settings:aws",
          route: "/settings/aws",
        },
        {
          pageId: "settings:aws:link",
          route: "/settings/aws/link",
        },
        {
          pageId: "settings:aws:account",
          route: "/settings/aws/:accountId",
        },
        {
          pageId: "settings:azure",
          route: "/settings/azure",
          allowAccess: (permissions, state) =>
            state.earlyAccessFeatures.includes(EarlyAccessFeature.AZURE_CLOUD_CONNECT),
        },
        {
          pageId: "settings:account-managers",
          route: "/settings/account-managers",
          allowAccess: (permissions) => permissions.doitEmployee,
        },
      ],
      allowAccess: (permissions) => permissions.doitEmployee || permissions.manageSettings,
    },
    {
      component: AuthenticationSettings,
      key: "Authentication",
      routes: [
        {
          pageId: "iam:sso",
          route: "/iam/authentication",
        },
        {
          pageId: "iam:sso:configure",
          route: "/iam/authentication/sso/:ssoType(saml|oidc)",
        },
        {
          pageId: "iam:auth-provider",
          route: "/iam/authentication/auth-provider",
        },
      ],
      allowAccess: (permissions) => permissions.doitEmployee || permissions.usersManager,
    },
    {
      component: AnomalyDetection,
      key: "AnomalyDetection",
      pageId: "anomaly-detection",
      route: "/anomaly",
      allowAccess: (permissions) => permissions.doitEmployee || permissions.anomaliesViewer,
      featureGate: "governance:costAnomalies",
      presentationModeSupported: true,
    },
    {
      component: CostAnomalyDetails,
      key: "AlertDetails",
      pageId: "anomaly-detection:details",
      route: "/anomaly/:assetId/:alertId",
      allowAccess: (permissions) => permissions.doitEmployee || permissions.anomaliesViewer,
      featureGate: "governance:costAnomalies",
      presentationModeSupported: true,
    },
    {
      component: CloudDiagramsDashboard,
      key: "CloudDiagramsDashboard",
      pageId: "cloud-diagrams",
      route: "/cloud-diagrams",
      allowAccess: (permissions, state) =>
        permissions.doitEmployee && state.earlyAccessFeatures.includes(EarlyAccessFeature.CLOUDDIAGRAMS),
    },
    {
      component: CloudDiagramsOnboarding,
      key: "CloudDiagramsOnboarding",
      pageId: "cloud-diagrams:onboarding",
      route: "/cloud-diagrams/onboarding",
      allowAccess: (permissions, state) =>
        permissions.doitEmployee && state.earlyAccessFeatures.includes(EarlyAccessFeature.CLOUDDIAGRAMS),
    },
    {
      component: Support,
      key: "Support",
      routes: [
        {
          pageId: "support",
          route: "/support",
        },
        {
          pageId: "support:new",
          route: "/support/new/:template?",
        },
        {
          pageId: "support:followup",
          route: "/support/followup/:fid",
        },
        {
          pageId: "support:tickets",
          route: "/support/tickets/:id/:rate?",
        },
      ],
      allowAccess: (permissions) => permissions.doitEmployee || permissions.supportRequester,
    },
    {
      component: KnownIssues,
      key: "KnownIssues",
      pageId: "known-issues:customer",
      route: "/known-issues",
      allowAccess: (permissions) => permissions.doitEmployee || permissions.issuesViewer,
      featureGate: "governance:cloudIncidents",
    },
    {
      component: AzureOnboarding,
      key: "SaaSOnboardingAzure",
      pageId: "saas:onboarding:azure",
      route: "/cloud-onboarding-azure",
      featureGate: "pdi:azure",
      allowAccess: (permissions, state) => isAllowedToOnboardStandaloneAsset(permissions, state, "pdi:azure"),
    },
    {
      component: SaaSOnboardingGCP,
      key: "SaaSOnboardingGCP",
      pageId: "saas:onboarding:gcp",
      route: "/cloud-onboarding-gcp",
      featureGate: "pdi:gcp",
      allowAccess: (permissions, state) => isAllowedToOnboardStandaloneAsset(permissions, state, "pdi:gcp"),
    },
    {
      component: SaaSOnboardingAWS,
      key: "SaaSOnboardingAWS",
      pageId: "saas:onboarding:aws",
      route: "/cloud-onboarding-aws",
      featureGate: "pdi:aws",
      allowAccess: (permissions, state) => isAllowedToOnboardStandaloneAsset(permissions, state, "pdi:aws"),
    },
    {
      component: SnowflakeOnboarding,
      key: "SaaSOnboardingSnowflake",
      pageId: "saas:onboarding:snowflake",
      route: "/cloud-onboarding-snowflake",
      allowAccess: (permissions, state) =>
        (permissions.doitEmployee || permissions.billingProfilesAdmin) &&
        (!state.isStandalone ||
          (state.startTrialInitiated && !state.navigatorTierTrialStartDate) ||
          state.navigatorTier?.trialTier ||
          state.navigatorTier?.type !== "internal"),
    },
    {
      component: DatadogOnboarding,
      key: "SaaSOnboardingDatadog",
      pageId: "saas:onboarding:datadog",
      route: "/cloud-onboarding-datadog",
      allowAccess: (permissions, state) =>
        (permissions.doitEmployee || permissions.billingProfilesAdmin) &&
        (!state.isStandalone ||
          (state.startTrialInitiated && !state.navigatorTierTrialStartDate) ||
          state.navigatorTier?.trialTier ||
          state.navigatorTier?.type !== "internal"),
    },
    {
      component: KnownIssueView,
      key: "KnownIssueView",
      pageId: "known-issues:customer:details",
      route: "/known-issues/:knownIssueId",
      allowAccess: (permissions) => permissions.doitEmployee || permissions.issuesViewer,
      featureGate: "governance:cloudIncidents",
    },
    ...budgetsRoutes,
    ...alertRoutes,
    {
      component: CloudAnalytics,
      key: "CloudAnalytics",
      routes: [
        {
          pageId: "analytics",
          route: "/analytics",
        },
        {
          pageId: "analytics:labels",
          route: "/analytics/labels",
          allowAccess: (permissions) => permissions.labelsManager,
        },
        {
          pageId: "analytics:reports",
          route: "/analytics/reports",
          presentationModeSupported: true,
        },
        {
          pageId: "analytics:reports:create",
          route: "/analytics/reports/create",
          presentationModeSupported: true,
          featureGate: "analytics:reports",
        },
        {
          pageId: "analytics:reports:details",
          route: "/analytics/reports/:reportId",
          presentationModeSupported: true,
        },
        {
          pageId: "analytics:report-templates",
          route: "/analytics/report-templates",
          presentationModeSupported: true,
          featureGate: "analytics:reports:templates",
          isFeatureGateDisabledForDoer: true,
        },
        {
          pageId: "analytics:attributions",
          route: "/analytics/attributions",
          presentationModeSupported: true,
          allowAccess: (permissions) => permissions.attributionsManager || permissions.doitEmployee,
          featureGate: "analytics:attributions:presets",
        },
        {
          pageId: "analytics:attributions:create",
          route: "/analytics/attributions/create",
          presentationModeSupported: true,
          allowAccess: (permissions) => permissions.attributionsManager || permissions.doitEmployee,
          featureGate: "analytics:attributions",
        },
        {
          pageId: "analytics:attributions:details",
          route: "/analytics/attributions/:attributionId",
          presentationModeSupported: true,
          allowAccess: (permissions) => permissions.attributionsManager || permissions.doitEmployee,
          featureGate: "analytics:attributions:presets",
        },
        {
          pageId: "analytics:attribution-groups",
          route: "/analytics/attribution-groups",
          presentationModeSupported: true,
          allowAccess: (permissions) => permissions.attributionsManager || permissions.doitEmployee,
          featureGate: "analytics:attributionGroups:presets",
        },
        {
          pageId: "analytics:attribution-groups:create",
          route: "/analytics/attribution-groups/create",
          presentationModeSupported: true,
          allowAccess: (permissions) => permissions.attributionsManager || permissions.doitEmployee,
          featureGate: "analytics:attributionGroups",
        },
        {
          pageId: "analytics:attribution-groups:edit",
          route: "/analytics/attribution-groups/:attributionGroupId",
          presentationModeSupported: true,
          allowAccess: (permissions) => permissions.attributionsManager || permissions.doitEmployee,
          featureGate: "analytics:attributionGroups:presets",
        },
        {
          pageId: "analytics:metrics",
          route: "/analytics/metrics",
          presentationModeSupported: true,
          allowAccess: (permissions) =>
            permissions.metricsManager || permissions.doitEmployee || permissions.cloudAnalyticsAdmin,
          featureGate: "analytics:calculatedMetrics:presets",
        },
        {
          pageId: "analytics:metrics:create",
          route: "/analytics/metrics/create",
          presentationModeSupported: true,
          allowAccess: (permissions) =>
            permissions.metricsManager || permissions.doitEmployee || permissions.cloudAnalyticsAdmin,
          featureGate: "analytics:calculatedMetrics",
        },
        {
          pageId: "analytics:metrics:details",
          route: "/analytics/metrics/:metricId",
          presentationModeSupported: true,
          allowAccess: (permissions) =>
            permissions.metricsManager || permissions.doitEmployee || permissions.cloudAnalyticsAdmin,
          featureGate: "analytics:calculatedMetrics:presets",
        },
        {
          pageId: "analytics:resources",
          route: "/analytics/all",
          presentationModeSupported: true,
          featureGate: "analytics:labels",
        },
        {
          pageId: "analytics:allocations",
          route: "/analytics/allocations",
          presentationModeSupported: true,
          allowAccess: (permissions, state) =>
            (permissions.cloudAnalyticsUser || permissions.doitEmployee) &&
            state.earlyAccessFeatures.includes(EarlyAccessFeature.ALLOCATIONS_PAGES),
        },
        {
          pageId: "analytics:allocations:create",
          route: "/analytics/allocations/create",
          presentationModeSupported: true,
          allowAccess: (permissions, state) =>
            (permissions.cloudAnalyticsUser || permissions.doitEmployee) &&
            state.earlyAccessFeatures.includes(EarlyAccessFeature.ALLOCATIONS_PAGES),
        },
        {
          pageId: "analytics:allocations:details",
          route: "/analytics/allocations/:allocationId",
          presentationModeSupported: true,
          allowAccess: (permissions, state) =>
            (permissions.cloudAnalyticsUser || permissions.doitEmployee) &&
            state.earlyAccessFeatures.includes(EarlyAccessFeature.ALLOCATIONS_PAGES),
        },
      ],
      allowAccess: (permissions) => permissions.cloudAnalyticsUser || permissions.doitEmployee,
    },
    {
      component: PerksRouter,
      key: "PerksEdit",
      routes: [
        {
          pageId: "isv-solutions:create",
          route: "/isv-solutions/create",
        },
        {
          pageId: "isv-solutions:edit",
          route: "/isv-solutions/edit/:perkId",
        },
        {
          pageId: "training:create",
          route: "/training/create",
        },
        {
          pageId: "training:edit",
          route: "/training/edit/:perkId",
        },
        {
          pageId: "professional-services:create",
          route: "/professional-services/create",
        },
        {
          pageId: "professional-services:edit",
          route: "/professional-services/edit/:perkId",
        },
      ],
      allowAccess: (permissions) => permissions.check(DoitRole.Owners) || permissions.check(DoitRole.PerksAdmin),
    },
    {
      component: PerksRouter,
      key: "Perks",
      routes: [
        {
          pageId: "isv-solutions",
          route: "/isv-solutions",
        },
        {
          pageId: "isv-solutions:view",
          route: "/isv-solutions/:perkId",
        },

        {
          pageId: "training",
          route: "/training",
        },
        {
          pageId: "training:view",
          route: "/training/:perkId",
        },
        {
          pageId: "professional-services",
          route: "/professional-services",
        },
        {
          pageId: "professional-services:view",
          route: "/professional-services/:perkId",
        },
      ],
      allowAccess: (permissions) => permissions.doitEmployee || permissions.perksViewer,
    },
    {
      component: ProductWorkshop,
      key: "LiveProductWorkshop",
      routes: [
        {
          pageId: "live-product-workshop",
          route: "/live-product-workshop",
        },
      ],
      allowAccess: () => true,
    },
  ],

  static: commonStaticRoutes,

  // by default only Doit employees can access those pages
  doers: doitEmployeeOnlyRoutes,
};

export const standaloneAccessControlConfig: AccessControlConfig = {
  customer: [
    ...commonCustomerRoutes,
    {
      component: FlexsaveStandaloneAWS,
      key: "FlexsaveStandaloneAWS",
      pageId: "flexsave-aws",
      route: "/flexsave-aws",
      allowAccess: (permissions, state) =>
        (permissions.flexsaveAdmin || permissions.doitEmployee) && state.hasFlexsaveEnabled,
    },
    {
      component: SpotScaling,
      key: "SpotScaling",
      routes: [
        {
          pageId: "spot-scaling",
          route: "/spot-scaling",
          featureGate: "savings:spotScaling",
          presentationModeSupported: true,
        },
        {
          pageId: "spot-scaling:apply",
          route: "/spot-scaling/:asgName",
          featureGate: "savings:spotScaling",
          presentationModeSupported: true,
        },
      ],
      allowAccess: (permissions, state) =>
        (permissions.spotScaling || permissions.doitEmployee) && (state.hasAWSStandaloneAssets || state.hasAWSAssets),
    },
    {
      component: SaaSOnboardingGCP,
      key: "SaaSOnboardingGCP",
      pageId: "saas:onboarding:gcp",
      route: "/cloud-onboarding-gcp",
      allowAccess: (permissions, state) => isAllowedToOnboardStandaloneAsset(permissions, state, "pdi:gcp"),
    },
    {
      component: SaaSOnboardingAWS,
      key: "SaaSOnboardingAWS",
      pageId: "saas:onboarding:aws",
      route: "/cloud-onboarding-aws",
      allowAccess: (permissions, state) => isAllowedToOnboardStandaloneAsset(permissions, state, "pdi:aws"),
    },
    {
      component: AzureOnboarding,
      key: "SaaSOnboardingAzure",
      pageId: "saas:onboarding:azure",
      route: "/cloud-onboarding-azure",
      allowAccess: (permissions, state) => isAllowedToOnboardStandaloneAsset(permissions, state, "pdi:azure"),
    },
    {
      component: SnowflakeOnboarding,
      key: "SaaSOnboardingSnowflake",
      pageId: "saas:onboarding:snowflake",
      route: "/cloud-onboarding-snowflake",
      allowAccess: (permissions, state) =>
        (permissions.doitEmployee || permissions.billingProfilesAdmin) &&
        (!state.isStandalone ||
          (state.startTrialInitiated && !state.navigatorTierTrialStartDate) ||
          state.navigatorTier?.trialTier ||
          state.navigatorTier?.type !== "internal"),
    },
    {
      component: Assets,
      key: "Assets",
      routes: [
        {
          pageId: "assets",
          route: "/assets",
          presentationModeSupported: true,
        },
        {
          pageId: "asset:page",
          route: "/assets/:tab/:assetId",
          presentationModeSupported: true,
        },
        {
          pageId: "assets:all",
          route: "/assets/:tab?/:assetId?/:transfer?",
          presentationModeSupported: true,
        },
      ],
      allowAccess: (permissions, state) =>
        (permissions.doitEmployee || permissions.assetsManager) && hasStandaloneRoutesAccess(state),
    },
    {
      component: ContractsTabs,
      key: "ContractsTabs",
      routes: [
        {
          pageId: "contracts",
          route: "/contracts",
        },
        {
          pageId: "contracts:list",
          route: "/contracts/contracts-list",
        },
        {
          pageId: "contracts:create",
          route: "/contracts/contracts-list/create",
          allowAccess: (permissions) => permissions.doitEmployee,
        },
        {
          pageId: "contracts:edit",
          route: "/contracts/contracts-list/:contractId",
          allowAccess: (permissions) => permissions.doitEmployee,
        },
        {
          pageId: "contracts:view",
          route: "/contracts/contracts-list/:contractId/view",
          allowAccess: (permissions) => permissions.doitEmployee || permissions.contractsViewer,
        },
        {
          pageId: "contracts:view-vendor-contract",
          route: "/contracts/contracts-list/:contractId/view-vendor-contract",
          allowAccess: (permissions) => permissions.doitEmployee,
        },
      ],
      allowAccess: (permissions, state) =>
        permissions.doitEmployee || (permissions.contractsViewer && hasStandaloneRoutesAccess(state)),
    },
    {
      component: BillingProfiles,
      key: "BillingProfiles",
      routes: [
        {
          pageId: "billing-profiles",
          route: "/billing-profiles",
        },
      ],
      allowAccess: (permissions) => permissions.billingProfilesAdmin || permissions.doitEmployee,
    },
    {
      component: IAM,
      key: "IAM",
      routes: [
        {
          pageId: "iam:users",
          route: "/iam/users",
        },
        {
          pageId: "iam:users:details",
          route: "/iam/users/:userId/:tab?",
        },
        {
          pageId: "iam:roles",
          route: "/iam/roles",
        },
        {
          pageId: "iam:roles:details",
          route: "/iam/roles/:roleId",
        },
        {
          pageId: "iam:account-assumption",
          route: "/iam/account-assumption",
        },
      ],
      allowAccess: (permissions) => permissions.doitEmployee || permissions.usersManager,
    },
    {
      component: PerksRouter,
      key: "Perks",
      routes: [
        {
          pageId: "isv-solutions",
          route: "/isv-solutions",
        },
        {
          pageId: "isv-solutions:view",
          route: "/isv-solutions/:perkId",
        },

        {
          pageId: "training",
          route: "/training",
        },
        {
          pageId: "training:view",
          route: "/training/:perkId",
        },
        {
          pageId: "professional-services",
          route: "/professional-services",
        },
        {
          pageId: "professional-services:view",
          route: "/professional-services/:perkId",
        },
      ],
      allowAccess: (permissions) => permissions.doitEmployee || permissions.perksViewer,
    },
    {
      component: PerksRouter,
      key: "PerksEdit",
      routes: [
        {
          pageId: "isv-solutions:create",
          route: "/isv-solutions/create",
        },
        {
          pageId: "isv-solutions:edit",
          route: "/isv-solutions/edit/:perkId",
        },
        {
          pageId: "training:create",
          route: "/training/create",
        },
        {
          pageId: "training:edit",
          route: "/training/edit/:perkId",
        },
        {
          pageId: "professional-services:create",
          route: "/professional-services/create",
        },
        {
          pageId: "professional-services:edit",
          route: "/professional-services/edit/:perkId",
        },
      ],
      allowAccess: (permissions) => permissions.check(DoitRole.Owners) || permissions.check(DoitRole.PerksAdmin),
    },
    {
      component: Invoices,
      key: "InvoicesList",
      routes: [
        {
          pageId: "invoices",
          route: "/invoices",
        },
        {
          pageId: "invoices:details",
          route: "/invoices/:eid/:ivnum",
        },
      ],
      allowAccess: (permissions, state) =>
        (permissions.doitEmployee || permissions.invoicesViewer) && hasStandaloneRoutesAccess(state),
    },
    {
      component: Settings,
      key: "Settings",
      routes: [
        {
          pageId: "settings",
          route: "/settings",
        },
        {
          pageId: "settings:account-managers",
          route: "/settings/account-managers",
          allowAccess: (permissions) => permissions.doitEmployee,
        },
        {
          pageId: "settings:general",
          route: "/settings/general",
        },
        {
          pageId: "settings:aws",
          route: "/settings/aws",
          allowAccess: (_permissions, state) => state.flexsaveAWSConfigured || state.saasConsoleAWSConfigured,
        },
        {
          pageId: "settings:gcp",
          route: "/settings/gcp",
          allowAccess: (_permissions, state) => state.saasConsoleGCPConfigured,
        },
      ],
      allowAccess: (permissions) => permissions.doitEmployee || permissions.manageSettings,
    },
    {
      component: Support,
      key: "Support",
      routes: [
        {
          pageId: "support",
          route: "/support",
        },
        {
          pageId: "support:new",
          route: "/support/new/:template?",
        },
        {
          pageId: "support:followup",
          route: "/support/followup/:fid",
        },
        {
          pageId: "support:tickets",
          route: "/support/tickets/:id/:rate?",
        },
      ],
      allowAccess: (permissions) => permissions.doitEmployee || permissions.supportRequester,
    },
    {
      component: AuthenticationSettings,
      key: "Authentication",
      routes: [
        {
          pageId: "iam:sso",
          route: "/iam/authentication",
        },
        {
          pageId: "iam:sso:configure",
          route: "/iam/authentication/sso/:ssoType(saml|oidc)",
        },
        {
          pageId: "iam:auth-provider",
          route: "/iam/authentication/auth-provider",
        },
      ],
      allowAccess: (permissions) => permissions.doitEmployee || permissions.usersManager,
    },
    {
      component: KnownIssues,
      key: "KnownIssues",
      pageId: "known-issues:customer",
      route: "/known-issues",
      allowAccess: (permissions, state) =>
        (permissions.issuesViewer || permissions.doitEmployee) && hasStandaloneRoutesAccess(state),
      featureGate: "governance:cloudIncidents",
    },
    {
      component: KnownIssueView,
      key: "KnownIssueView",
      pageId: "known-issues:customer:details",
      route: "/known-issues/:knownIssueId",
      allowAccess: (permissions, state) =>
        (permissions.issuesViewer || permissions.doitEmployee) && hasStandaloneRoutesAccess(state),
      featureGate: "governance:cloudIncidents",
    },
    ...budgetsRoutes,
    ...alertRoutes,
    {
      component: CloudAnalytics,
      key: "CloudAnalytics",
      routes: [
        {
          pageId: "analytics",
          route: "/analytics",
        },
        {
          pageId: "analytics:reports",
          route: "/analytics/reports",
          presentationModeSupported: true,
        },
        {
          pageId: "analytics:reports:create",
          route: "/analytics/reports/create",
          presentationModeSupported: true,
          featureGate: "analytics:reports",
        },
        {
          pageId: "analytics:reports:details",
          route: "/analytics/reports/:reportId",
          presentationModeSupported: true,
        },
        {
          pageId: "analytics:report-templates",
          route: "/analytics/report-templates",
          featureGate: "analytics:reports:templates",
          presentationModeSupported: true,
          isFeatureGateDisabledForDoer: true,
        },
        {
          pageId: "analytics:attributions",
          route: "/analytics/attributions",
          presentationModeSupported: true,
          allowAccess: (permissions) => permissions.attributionsManager || permissions.doitEmployee,
          featureGate: "analytics:attributions:presets",
        },
        {
          pageId: "analytics:attributions:create",
          route: "/analytics/attributions/create",
          presentationModeSupported: true,
          allowAccess: (permissions) => permissions.attributionsManager || permissions.doitEmployee,
          featureGate: "analytics:attributions",
        },
        {
          pageId: "analytics:attributions:details",
          route: "/analytics/attributions/:attributionId",
          presentationModeSupported: true,
          allowAccess: (permissions) => permissions.attributionsManager || permissions.doitEmployee,
          featureGate: "analytics:attributions:presets",
        },
        {
          pageId: "analytics:attribution-groups",
          route: "/analytics/attribution-groups",
          presentationModeSupported: true,
          allowAccess: (permissions) => permissions.attributionsManager || permissions.doitEmployee,
          featureGate: "analytics:attributionGroups:presets",
        },
        {
          pageId: "analytics:attribution-groups:create",
          route: "/analytics/attribution-groups/create",
          presentationModeSupported: true,
          allowAccess: (permissions) => permissions.attributionsManager || permissions.doitEmployee,
          featureGate: "analytics:attributionGroups",
        },
        {
          pageId: "analytics:attribution-groups:edit",
          route: "/analytics/attribution-groups/:attributionGroupId",
          presentationModeSupported: true,
          allowAccess: (permissions) => permissions.attributionsManager || permissions.doitEmployee,
          featureGate: "analytics:attributionGroups:presets",
        },
        {
          pageId: "analytics:metrics",
          route: "/analytics/metrics",
          presentationModeSupported: true,
          allowAccess: (permissions) => permissions.metricsManager || permissions.doitEmployee,
          featureGate: "analytics:calculatedMetrics:presets",
        },
        {
          pageId: "analytics:metrics:create",
          route: "/analytics/metrics/create",
          presentationModeSupported: true,
          allowAccess: (permissions) => permissions.metricsManager || permissions.doitEmployee,
          featureGate: "analytics:calculatedMetrics",
        },
        {
          pageId: "analytics:metrics:details",
          route: "/analytics/metrics/:metricId",
          presentationModeSupported: true,
          allowAccess: (permissions) => permissions.metricsManager || permissions.doitEmployee,
          featureGate: "analytics:calculatedMetrics:presets",
        },
      ],
      allowAccess: (permissions, state) =>
        (permissions.cloudAnalyticsUser || permissions.doitEmployee) && hasStandaloneRoutesAccess(state),
    },
    {
      component: AnomalyDetection,
      key: "AnomalyDetection",
      pageId: "anomaly-detection",
      route: "/anomaly",
      allowAccess: (permissions, state) =>
        (permissions.doitEmployee || permissions.anomaliesViewer) && hasStandaloneRoutesAccess(state),
      featureGate: "governance:costAnomalies",
      presentationModeSupported: true,
    },
    {
      component: CostAnomalyDetails,
      key: "AlertDetails",
      pageId: "anomaly-detection:details",
      route: "/anomaly/:assetId/:alertId",
      allowAccess: (permissions, state) =>
        (permissions.doitEmployee || permissions.anomaliesViewer) && hasStandaloneRoutesAccess(state),
      featureGate: "governance:costAnomalies",
      presentationModeSupported: true,
    },
    {
      component: Integrations,
      key: "integrations",
      routes: [
        {
          pageId: "integrations:zapier",
          route: "/integrations/zapier",
        },
        {
          pageId: "integrations:slack",
          route: "/integrations/slack",
        },
        {
          pageId: "integrations:jira",
          route: "/integrations/jira",
          featureGate: "governance:threads",
        },
        {
          pageId: "integrations:ms-teams",
          route: "/integrations/msteams",
        },
      ],
      allowAccess: (permissions) => permissions.doitEmployee || permissions.manageSettings,
    },
    {
      component: ProductWorkshop,
      key: "LiveProductWorkshop",
      routes: [
        {
          pageId: "live-product-workshop",
          route: "/live-product-workshop",
        },
      ],
      allowAccess: () => true,
    },
    {
      component: StartTrialStepper,
      key: "NavigatorTrialStepper",
      routes: [
        {
          pageId: "navigator-trial",
          route: "/start-trial",
        },
      ],
      allowAccess: () => true,
    },
  ],

  static: commonStaticRoutes,

  doers: doitEmployeeOnlyRoutes,
};

export const partnerAccessControlConfig: AccessControlConfig = {
  customer: [
    ...commonMarketplaceRoutes,
    {
      component: Assets,
      key: "Assets",
      routes: [
        {
          pageId: "assets",
          route: "/assets",
        },
        {
          pageId: "asset:page",
          route: "/assets/:tab/:assetId",
        },
        {
          pageId: "assets:all",
          route: "/assets/:tab?/:assetId?/:transfer?",
        },
      ],
      allowAccess: (permissions, state) =>
        (permissions.doitEmployee || permissions.assetsManager) && state.flexsaveGCPConfigured,
    },
    {
      component: ContractsTabs,
      key: "ContractsTabs",
      routes: [
        {
          pageId: "contracts",
          route: "/contracts",
        },
        {
          pageId: "contracts:list",
          route: "/contracts/contracts-list",
        },
        {
          pageId: "contracts:create",
          route: "/contracts/contracts-list/create",
          allowAccess: (permissions) => permissions.doitEmployee,
        },
        {
          pageId: "contracts:edit",
          route: "/contracts/contracts-list/:contractId",
          allowAccess: (permissions) => permissions.doitEmployee,
        },
        {
          pageId: "contracts:view",
          route: "/contracts/contracts-list/:contractId/view",
          allowAccess: (permissions) => permissions.doitEmployee || permissions.contractsViewer,
        },
        {
          pageId: "contracts:view-vendor-contract",
          route: "/contracts/contracts-list/:contractId/view-vendor-contract",
          allowAccess: (permissions) => permissions.doitEmployee,
        },
      ],
      allowAccess: (permissions, state) =>
        permissions.doitEmployee ||
        (permissions.contractsViewer && (state.flexsaveAWSConfigured || state.flexsaveGCPConfigured)),
    },
    {
      component: IAM,
      key: "IAM",
      routes: [
        {
          pageId: "iam:users",
          route: "/iam/users",
        },
        {
          pageId: "iam:users:details",
          route: "/iam/users/:userId/:tab?",
        },
        {
          pageId: "iam:roles",
          route: "/iam/roles",
        },
        {
          pageId: "iam:roles:details",
          route: "/iam/roles/:roleId",
        },
      ],
      allowAccess: (permissions) => permissions.doitEmployee || permissions.usersManager,
    },
    {
      component: Invoices,
      key: "InvoicesList",
      routes: [
        {
          pageId: "invoices",
          route: "/invoices",
        },
        {
          pageId: "invoices:details",
          route: "/invoices/:eid/:ivnum",
        },
      ],
      allowAccess: (permissions, state) =>
        (permissions.doitEmployee || permissions.invoicesViewer) && state.flexsaveGCPConfigured,
    },
    {
      component: Settings,
      key: "Settings",
      routes: [
        {
          pageId: "settings",
          route: "/settings",
        },
        {
          pageId: "settings:general",
          route: "/settings/general",
        },
      ],
      allowAccess: (permissions) => permissions.doitEmployee || permissions.manageSettings,
    },
    {
      component: Support,
      key: "Support",
      routes: [
        {
          pageId: "support",
          route: "/support",
        },
        {
          pageId: "support:new",
          route: "/support/new/:template?",
        },
        {
          pageId: "support:followup",
          route: "/support/followup/:fid",
        },
        {
          pageId: "support:tickets",
          route: "/support/tickets/:id/:rate?",
        },
      ],
      allowAccess: (permissions) => permissions.doitEmployee || permissions.supportRequester,
    },
    {
      component: AuthenticationSettings,
      key: "Authentication",
      routes: [
        {
          pageId: "iam:sso",
          route: "/iam/authentication",
        },
        {
          pageId: "iam:sso:configure",
          route: "/iam/authentication/sso/:ssoType(saml|oidc)",
        },
        {
          pageId: "iam:auth-provider",
          route: "/iam/authentication/auth-provider",
        },
      ],
      allowAccess: (permissions) => permissions.doitEmployee || permissions.usersManager,
    },
    {
      component: KnownIssues,
      key: "KnownIssues",
      pageId: "known-issues:customer",
      route: "/known-issues",
      allowAccess: (permissions) => permissions.doitEmployee || permissions.issuesViewer,
      featureGate: "governance:cloudIncidents",
    },
    {
      component: KnownIssueView,
      key: "KnownIssueView",
      pageId: "known-issues:customer:details",
      route: "/known-issues/:knownIssueId",
      allowAccess: (permissions) => permissions.doitEmployee || permissions.issuesViewer,
      featureGate: "governance:cloudIncidents",
    },
    ...budgetsRoutes,
    {
      component: CloudAnalytics,
      key: "CloudAnalytics",
      routes: [
        {
          pageId: "analytics",
          route: "/analytics",
        },
        {
          pageId: "analytics:reports",
          route: "/analytics/reports",
          presentationModeSupported: true,
        },
        {
          pageId: "analytics:reports:create",
          route: "/analytics/reports/create",
          featureGate: "analytics:reports",
        },
        {
          pageId: "analytics:reports:details",
          route: "/analytics/reports/:reportId",
        },
        {
          pageId: "analytics:report-templates",
          route: "/analytics/report-templates",
          featureGate: "analytics:reports:templates",
          isFeatureGateDisabledForDoer: true,
        },
        {
          pageId: "analytics:attributions",
          route: "/analytics/attributions",
          allowAccess: (permissions) => permissions.attributionsManager || permissions.doitEmployee,
          featureGate: "analytics:attributions:presets",
        },
        {
          pageId: "analytics:attributions:create",
          route: "/analytics/attributions/create",
          allowAccess: (permissions) => permissions.attributionsManager || permissions.doitEmployee,
          featureGate: "analytics:attributions",
        },
        {
          pageId: "analytics:attributions:details",
          route: "/analytics/attributions/:attributionId",
          allowAccess: (permissions) => permissions.attributionsManager || permissions.doitEmployee,
          featureGate: "analytics:attributions:presets",
        },
        {
          pageId: "analytics:metrics",
          route: "/analytics/metrics",
          allowAccess: (permissions) => permissions.metricsManager || permissions.doitEmployee,
          featureGate: "analytics:calculatedMetrics:presets",
        },
        {
          pageId: "analytics:metrics:create",
          route: "/analytics/metrics/create",
          allowAccess: (permissions) => permissions.metricsManager || permissions.doitEmployee,
          featureGate: "analytics:calculatedMetrics",
        },
        {
          pageId: "analytics:metrics:details",
          route: "/analytics/metrics/:metricId",
          allowAccess: (permissions) => permissions.metricsManager || permissions.doitEmployee,
          featureGate: "analytics:calculatedMetrics:presets",
        },
      ],
      allowAccess: (permissions, state) =>
        (permissions.doitEmployee || permissions.cloudAnalyticsUser) && state.flexsaveGCPConfigured,
    },
    {
      component: CustomerViewForPartner,
      key: "CustomerViewForPartner",
      routes: [
        {
          pageId: "customer-dashboards",
          route: "/dashboards/:name?",
        },
        {
          pageId: "customer",
          route: "/",
        },
      ],
    },
    {
      component: EntityForm,
      key: "EntityCreate",
      pageId: "entity-create",
      route: "/entities",
      allowAccess: (permissions) => permissions.billingProfilesAdmin || permissions.doitEmployee,
    },
  ],

  static: [],
  doers: [],
};
